/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import type {Context} from "react"

import type {BigCommerceGql_Product, LayoutQuery} from "../../graphql-types"

import {graphql, useStaticQuery} from "gatsby"
import React, {useEffect, useState} from "react"
import styled, {ThemeProvider} from "styled-components"
import Cookies from "universal-cookie"

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import type {Bag} from "../../types/BigCommerce"

import {GlobalStyle} from "../styles/GlobalStyle"

import {getCartContents, deleteCart} from "../utils/carts"
import {redirectToMaintenancePage} from "../utils/maintenance"

import {theme} from "../theme"

import {BagContext} from "./Bag"
import {UserContext} from "./User"
import {PromotionalBanner} from "./PromotionalBanner"
import {GlobalFooter} from "./GlobalFooter"
import {GlobalHeader} from "./GlobalHeader"
import {SiteSelector} from "./SiteSelector"
import {Snackbar, SnackbarContext} from "./Snackbar"
import {browserScope, customDimensionsEvents} from "../utils/gtm"
import {isSignedIn, getUserToken} from "../utils/user"
import {getOrders} from "../utils/orders_dataService"
import {getWishlistsByCustomerToken} from "../utils/wishlists_dataService"

Bugsnag.start({
  apiKey: '0b702d6fac4a5d1f050dba11817b5f98',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const StyledSiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  min-height: 100vh;
`

const StyledPageContainer = styled.div`
  display: flex;
  flex: 1;
`

const StyledContentArea = styled.div`
  flex-basis: 100%;
  max-width: 100vw;

  &.flex {
    display: flex;
  }
`

export type LayoutProps = {
  children?: React.ReactNode
  type?: "compact" | "full"
  transparent?: boolean
}

const siteContextDefault: {
  bestSellingProducts?: {
    edges: Array<{
      node: BigCommerceGql_Product
    }>
  }
  featuredProducts?: {
    edges: Array<{
      node: BigCommerceGql_Product
    }>
  }
} = {}

export const SiteContext: Context<Site> = React.createContext(
  siteContextDefault
)

export const Layout: React.FC<LayoutProps> = ({children, ...props}) => {


  const data: LayoutQuery = useStaticQuery(graphql`
    query Layout {
      site {
        siteMetadata {
          title
        }
      }
      allContentstackMenuComponent(
        filter: {
          slot: {
            in: [
              "footer-secondary-1"
              "footer-secondary-2"
              "footer-secondary-3"
              "footer-tertiary-1"
              "header-navigation"
              "mobile-navigation"
            ]
          }
        }
      ) {
        edges {
          node {
            ...Contentstack_menu_componentFragment
          }
        }
      }
      bigCommerceGQL {
        site {
          bestSellingProducts {
            edges {
              node {
                ...BigCommerceGQL_ProductFragment
              }
            }
          }
          featuredProducts {
            edges {
              node {
                ...BigCommerceGQL_ProductFragment
              }
            }
          }
          settings {
            status
          }
        }
      }
      contentstackPromotionalBannerComponent {
        link {
          href
          title
        }
        title
      }
      siteSearchIndex {
        index
      }
    }
  `)
  // console.log("DATAAAA", data)
  // const { bag, setBag } = useContext(BagContext)
  const PARAM_SET_CURRENCY = 'setCurrencyId';
  const [snackbarLabelText, setSnackbarLabelText] = useState<string | undefined>(undefined)
  const setSnackbar = (labelText: string | undefined) => {
    // console.log("labelText", labelText)
    setSnackbarLabelText(labelText)
  }

  const [bagContents, setBagContents] = useState({} as Bag)
  const [user, setUser] = useState({} as any)
  const cookies = new Cookies()

  const setBag = (bag: Bag) => {
    if (bag.id) {
      localStorage.setItem("cartId", bag.id);
      cookies.set('cartId', bag.id, {path: '/'});


      if (!cookies.get('bagRefreshed') || cookies.get('bagRefreshed') == 'N') {
        //Set cookie to bagRefreshed = Y
        cookies.set('bagRefreshed', 'Y', {path: '/'});
        // console.log("BAG COOKIE");

        //Refresh page
        if (window?.location?.reload) {
          window.location.reload();
        }
      } else {
        cookies.set('bagRefreshed', 'N', {path: '/'});
      }


    } else {
      localStorage.removeItem("cartId");
      cookies.remove('cartId');
      cookies.remove('bagRefreshed');
    }

    setBagContents(bag)
  }


  // console.log("bagContents", bagContents)

  useEffect(() => {
    redirectToMaintenancePage(data.bigCommerceGQL.site.settings.status);
    getCartContents(setBag);

    getWishlistsByCustomerToken()
      .then(wishlistsData => {
        // console.log("getAllWishlists", wishlistsData);

        if (wishlistsData && wishlistsData.data && wishlistsData.data.length > 0) {
          localStorage.setItem("wishlistId", wishlistsData.data[0].id);
        }

        // console.log("getAllWishlists", localStorage.getItem("wishlistId"));
      })
      .catch(err => {
        console.log("error: ", err);
      });

    //static set cookie - need to make it dynamic
    let currencies = [
      {"code": "GBP", "id": "1", "switch_url": `${process.env.GATSBY_SITE_URL}?setCurrencyId=1`},
      {"code": "EUR", "id": "2", "switch_url": `${process.env.GATSBY_SITE_URL}?setCurrencyId=2`},
      {"code": "AUD", "id": "3", "switch_url": `${process.env.GATSBY_SITE_URL}?setCurrencyId=3`},
      {"code": "CZK", "id": "4", "switch_url": `${process.env.GATSBY_SITE_URL}?setCurrencyId=4`},
      {"code": "DKK", "id": "5", "switch_url": `${process.env.GATSBY_SITE_URL}?setCurrencyId=5`},
      {"code": "HKD", "id": "6", "switch_url": `${process.env.GATSBY_SITE_URL}?setCurrencyId=6`},
      {"code": "JYP", "id": "7", "switch_url": `${process.env.GATSBY_SITE_URL}?setCurrencyId=7`},
      {"code": "KRW", "id": "8", "switch_url": `${process.env.GATSBY_SITE_URL}?setCurrencyId=8`},
      {"code": "PLN", "id": "9", "switch_url": `${process.env.GATSBY_SITE_URL}?setCurrencyId=9`},
      {"code": "RUB", "id": "10", "switch_url": `${process.env.GATSBY_SITE_URL}?setCurrencyId=10`},
      {"code": "SEK", "id": "11", "switch_url": `${process.env.GATSBY_SITE_URL}?setCurrencyId=11`},
      {"code": "CHF", "id": "12", "switch_url": `${process.env.GATSBY_SITE_URL}?setCurrencyId=12`},
      {"code": "AED", "id": "13", "switch_url": `${process.env.GATSBY_SITE_URL}?setCurrencyId=13`}
    ]

    let currenciesString = JSON.stringify(currencies);

    // cookies.set('storeHash', process.env.BIGCOMMERCE_STORE_HASH, { path: '/' });

    if (process.env.GATSBY_ENV == 'STAGING') {
      cookies.set('storeHash', "9o6tufixs6", {path: '/'});
    } else if (process.env.GATSBY_ENV == 'US_STAGING') {
      cookies.set('storeHash', "fj9kxa9upa", {path: '/'});
    } else if (process.env.GATSBY_ENV == 'US_PRODUCTION') {
      cookies.set('storeHash', "qm64xjz501", {path: '/'});
    } else {
      cookies.set('storeHash', "8jn8gktkk2", {path: '/'});
    }

    if (browserScope()) {

      if (isSignedIn()) {
        customDimensionsEvents({userId: getUserToken()});
        getOrders()
          .then(ordersData => {
            if (ordersData.data) {
              customDimensionsEvents({orderCount: ordersData.data.length.toString()});
            } else {
              customDimensionsEvents({orderCount: "0"});
            }
          });

      }

      window.addEventListener('load', function () {

        if (document.querySelector('#ge_countryName') !== null) {

          if (document.querySelector('#ge_countryName') !== null) {
            // siteLocale = document.querySelector('#ge_countryName').innerHTML;
            customDimensionsEvents({
              language: document.documentElement.lang ? document.documentElement.lang : "en-gb",
              country: document.querySelector('#ge_countryName')?.innerHTML ? document.querySelector('#ge_countryName')?.innerHTML : ""
            });
          }
        }
      });

      if (process.env.GATSBY_ENV?.toLowerCase().includes("us")) {
        customDimensionsEvents({
          language: document.documentElement.lang ? document.documentElement.lang : "en-us",
          country: "United States"
        });
      }

      if (window.cookieAccepted) {
        window.dataLayer.push({
          'event': 'virtualPageView'
        });
        if (window.location.pathname === "" || window.location.pathname === "/") {
          if (window.gtag) {
            window.gtag('event', 'conversion', {
              'allow_custom_scripts': true,
              'send_to': 'DC-11008057/msix/' + process.env.GATSBY_GTM_ACTIVITY_ID + '+standard'
            });
          }
        }
      }

      if (!process.env.GATSBY_ENV?.toLowerCase().includes("us")) {
        const currencyId = new URL(window.location.href).searchParams.get(
          PARAM_SET_CURRENCY,
        );

        if (currencyId) {

          let shouldRefresh = false;
          if (currencyId !== cookies.get('geOriginalCurrencyId')) {
            // console.log('currency changed - refresh needed')
            shouldRefresh = true;
          }

          cookies.set('geOriginalCurrencyId', currencyId, {path: '/'});
          cookies.set(
            'geOriginalCurrencyCode',
            currencies.find(c => c.id === currencyId)?.code,
            {path: '/'},
          );

          if (shouldRefresh) {
            // console.log('currency changed - removing current cart')
            deleteCart(setBag);
            if (window?.location?.reload) {
              window.location.reload(false);
            }
          }

        }
      }

    }

    if (!process.env.GATSBY_ENV?.toLowerCase().includes("us")) {
      if (!cookies.get('geOriginalCurrencyId')) {
        cookies.set('geOriginalCurrencyId', '1', {path: '/'});
      }
      if (!cookies.get('geOriginalCurrencyCode')) {
        cookies.set('geOriginalCurrencyCode', 'GBP', {path: '/'});
        window.location.reload();
      }
      cookies.set('geOriginalCurrencies', currenciesString, {path: '/'});
    }


    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    if (process.env.GATSBY_ENV === 'LOCAL' || process.env.GATSBY_ENV?.toLowerCase().includes("us")) {
      s.src = '';
    } else if (process.env.GATSBY_ENV === 'STAGING') {
      s.src = '//intgepi.bglobale.com/includes/js/824';
    } else {
      s.src = '//gepi.global-e.com/includes/js/824';
    }
    document.body.appendChild(s);

    //Bugsnag.notify(new Error('Test error'))


    // <script
    //     dangerouslySetInnerHTML={{
    //       __html: `(function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(d.getElementById(id)){return;}js=d.createElement(s);js.id=id;js.src='https://trust.poslux.com/trustmark.js';fjs.parentNode.insertBefore(js,fjs);})(document,'script','pl-trustmark');`,
    //     }}
    //   >
    // </script>

  }, []);


  return (
    <ErrorBoundary>
      {(typeof window !== 'undefined' && (window.location.pathname === "" || window.location.pathname === "/")) &&
        <noscript>
          <img src={"https://ad.doubleclick.net/ddm/activity/src=11008057;type=msix;cat=" + process.env.GATSBY_GTM_ACTIVITY_ID + ";dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"} width="1" height="1" alt="" />
        </noscript>
      }
      <ThemeProvider theme={theme}>
        <BagContext.Provider value={{bag: bagContents, setBag}}>
          <UserContext.Provider value={{user: user, setUser}}>
            <SnackbarContext.Provider
              value={{labelText: snackbarLabelText, setSnackbar}}
            >
              <StyledSiteContainer {...props}>
                <GlobalStyle theme={theme} />

                <SiteSelector />

                {props.type === "compact" ? (
                  <StyledPageContainer>
                    <StyledContentArea className="flex">
                      {children}
                    </StyledContentArea>
                  </StyledPageContainer>
                ) : (
                  <React.Fragment>
                    {data?.contentstackPromotionalBannerComponent?.title && (
                      <PromotionalBanner
                        {...data?.contentstackPromotionalBannerComponent?.link}
                        variant="primary"
                      />
                    )}

                    <GlobalHeader
                      // bag={bag}
                      data={data}
                      siteTitle={data?.site?.siteMetadata?.title || `Title`}
                      transparent={props.transparent}
                    />

                    <StyledPageContainer>
                      <SiteContext.Provider value={data?.bigCommerceGQL?.site}>
                        <StyledContentArea>{children}</StyledContentArea>
                      </SiteContext.Provider>
                    </StyledPageContainer>

                    <GlobalFooter
                      data={data}
                      siteTitle={data?.site?.siteMetadata?.title || `Title`}
                    />
                  </React.Fragment>
                )}
                <Snackbar labelText={snackbarLabelText} setSnackbar={setSnackbar} />
              </StyledSiteContainer>
            </SnackbarContext.Provider>
          </UserContext.Provider>
        </BagContext.Provider>
      </ThemeProvider>
    </ErrorBoundary>
  )
}
