import { themeGet } from "@styled-system/theme-get";
import AOS from "aos";
import "aos/dist/aos.css";
import clsx from "clsx";
import { MutableRefObject, useContext } from "react";
import React, { useEffect, useRef, useState } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import styled from "styled-components";
import { Banner } from "../components/Banner";
import { Button, Link, LinkButton } from "../components/Button";
import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";
import { useIntersection } from "../hooks/useIntersection";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { mediaQueries } from "../theme";

import { ModalGiftFinder } from "../components/ModalGiftFinder";
import Carousel from "../components/Carousel";
import { ShareOverlay } from "../components/ShareOverlay";
import { dataQuestions, dataAnswers } from "../utils/giftFinder_data";

import { ReactComponent as Arrow } from "../../static/icons/Arrow.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const LoaderStyled = styled.div`
  .overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: #d3cbd6;
    z-index: 99999;
    opacity: 1;
    pointer-events: none;
  }

  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .overlay__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 4px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #363139;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .loading {
    font-size: var(--font-size-heading-1, 32px);
    font-family: "Tiempos", Times, serif;
    font-weight: lighter;
    text-transform: capitalize;
    position: relative;
    overflow: hidden;
    transform: translate3d(-50%, -60%);

    &:before {
      color: #aaa;
      content: attr(data-loading-text);
    }

    &:after {
      top: 0;
      left: 0;
      width: 0;
      opacity: 1;
      color: #363139;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      content: attr(data-loading-text);
      animation: loading 1.5s infinite linear;
    }

    @keyframes loading {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
`;

const MainStyled = styled.main`


position:relative;
overflow:visible;
background:#fff;
z-index:1;
padding-bottom:20px;

&::before{
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2rem;
  top: -2rem;
  background: inherit;
}

.content {
  padding-right: 0;
  padding-bottom: 41px;

  ${mediaQueries.smDown}{
    padding-right: 16px;
    padding-left: 16px;
  }

  .text {
    margin-top: 0;
  }
}

.banner-section {
  padding: 0;
  margin-top: 0;
}

.thank-you-banner {
  height: 292px;
  border-radius: 0 0 20px 20px;
  background-color: #FEEBDD;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px;
 

  ${mediaQueries.smDown}{
    text-align: center;
    min-height: 241px;
    border-radius: 0 0 10px 10px;
    padding: 30px 20px;
  }

  h1 {
    ${mediaQueries.smDown}{
      font-size: 20px;
      letter-spacing: 0.4px;
      line-height: 26px;
    }
  }

  .gift-result-info {
    color: #363139;
    font-family: "Quicksand";
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
    margin: 0;
    max-width: 700px;
    text-align: center;
  }

  span {
    ${mediaQueries.smDown}{
      font-size: 13px;
      letter-spacing: 1px;
      line-height: 17px;
    }

  }

  .finder-btn {
    margin: 20px auto;
    width: 282px;
    position: relative;

    div {
      position: absolute;
      opacity: 0;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  a {
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  }
}

.thank-you-big-banner {
  max-width: 1266px;
  padding: 0;
  .e-container {
    width: 702px;
    margin: 30px auto 0;

    a {
      width: 282px;
      margin: 20px auto 0;
    }

    ${mediaQueries.smDown}{
      max-width: 355px;
      width: 100%;

      a {
        width: 100%;
      }

      span {
        letter-spacing: 1px;
        line-height: 17px;
      }
    }

    
    @media screen and (max-width: 320px){
      max-width: 300px;
    }
    
  }
}

.gift-finder-container {

  max-width: 702px;
  margin: 0 auto;

  ${mediaQueries.smDown}{
    padding: 0 30px;
  }

  button {
    margin: 2px auto 50px;
    width: 282px;

    ${mediaQueries.smDown}{
      width: 100%;
    }
  }


  div.imageHolder {
    position: relative;
    z-index: 100;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);


    img {
      width:250px;
      &.animateMe{
           opacity: 1;
           transform: translateZ(0);
      }
      transform: translateX(-100);

      ${mediaQueries.smDown}{
        width:300px;
      }
    }

  }


  div.animation-holder {

    position: absolute;
    top: 0;

    &.animateMe {
      div {
        opacity: 0.8;
      }

      .image1 {
        width:50%
        transform: rotate(39deg)translate3d(55%,-30%,0);
      }
      div:nth-child(2) {
        transform: rotate(10deg)translate3d(-10%,50%,0);
      }
      div:nth-child(3) {
        transform: rotate(20deg)translate3d(60%,55%,0);
      }
      div:nth-child(4) {
        transform: rotate(20deg)translate3d(-10%,100%,0);
      }
      div:nth-child(5) {
        transform: rotate(7deg)translate3d(105%,15%,0);
      }
      div:nth-child(6) {
        transform: rotate(20deg)translate3d(160%,-60%,0);
      }


    }

    > div {
      display: inline-block;
      width: 600px;
      height: 400px;
      background: url("./images/flowerPattern.svg") no-repeat;
      background-size: contain;
      z-index: 1;
      opacity: 1;
      position: absolute;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);

      &:nth-child(1) {

          transform: rotate(39deg)translateX(-20%)translateY(15%);
          transition: all 1200ms ease-in;
        // transition: all 1000ms ease-in;
        // left: 0;
        // top: 0;
        // transform: rotate(39deg) translateX(0);
      }

      &:nth-child(2) {
        transition: all 1200ms ease-in;
        transform: rotate(-30deg)translateX(-60%)translateY(10%);
        // left: -20%;
        // top: 10%;
        // transform: rotate(5deg);
      }

      &:nth-child(3) {
        // transform: rotate(-15deg);
        // left: -10%;
        // top: 60%;
        transform: rotate(-18deg)translate3d(-30%,120%,0);
        transition: all 1300ms ease-in;
      }

      &:nth-child(4) {
        // transform: rotate(0deg);
        transform: rotate(-18deg)translate3d(-70%,90%,0);
        transition: all 1500ms ease-in;
        // left: 10%;
        // top: 70%;
      }

      &:nth-child(5) {
        transform: rotate(-18deg)translate3d(120%,60%,0);
        transition: all 1000ms ease-in;
        // transform: rotate(-139deg);
        // transition: all 1000ms ease-in;
        // top: 0;
        // right: -10%
      }

      &:nth-child(6) {
        transition: all 1200ms ease-in;
        transform: rotate(-10deg)translate3d(170%,80%,0);
        // right: -20%;
        // top: 10%;
        // transform: rotate(5deg);
      }
    }
  }

}


  section {
    margin-block-end: ${themeGet("space.11")}px;
    margin-block-start: ${themeGet("space.11")}px;
    text-align: center;

    > .title,
    > .text,
    > .link {
      margin-block-end: unset;
      margin-block-start: unset;
      margin-inline-end: auto;
      margin-inline-start: auto;
      max-width: 90ch;
    }

    > p {
      line-height: 20px;
    }

    > .link {
      display: inline-block;
      font-weight: bold;
      margin-top: 30px;
      margin-block-end: ${themeGet("space.9")}px;
      text-transform: uppercase;
      &:hover {
        cursor: pointer;
      }
     
    }

    > .title {
      font-size: var(--font-size-heading-1, 24px);
      letter-spacing: .5px;
    }
  }

  .mobile-container {
    padding: 0px;
    border-radius: 0px;
    max-width: 375px;

    ${mediaQueries.sm}{
      max-width: 100%;
      margin-top: 0;
    }

  }



  .bottle-figure {
    [data-aos="main-bottle"] {
      transform: rotate(360deg);
      opacity: 0;
      transition-property: transform, opacity;
      &.aos-animate {
        transform: rotate(0);
        opacity: 1;
      }
    }


    display: flex;
    justify-content: center;
    margin-block-start: ${themeGet("space.13")}px;
    margin-block-end: 0px;
    ${mediaQueries.md}{
      margin-block-end: 160px;
    }
    position: relative;

    .pattern {
      width: 100%;
      ${mediaQueries.md}{
        width: 40%;
        max-width: 50%;
      }
      position: absolute;
      -webkit-filter: drop-shadow( 0px 0px 1px rgba(0,0,0,0.1));
      drop-shadow( 0px 0px 1px rgba(0,0,0,0.1))
    }

    .bottle {
      min-width: 300px;
      ${mediaQueries.md}{
        min-width: 300px;
      }
      z-index: 100;
      display: inline-block;
      -webkit-filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, 0.9));
      filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, 0.9));

    }
    [data-aos="first-image"] {
      position: absolute;
      transform: translateX(0);
      opacity: 0;
      transition-property: transform, opacity;
      &.aos-animate {
      transform: rotate(39deg)translateX(-35%)translateY(25%);
      opacity: 0.8;
      }
    }
    [data-aos="second-image"] {
      position: absolute;
      transform: rotate(-15deg)translateX(-130%)translateY(0%);
      opacity: 0;
      transition: all 1000ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(-100%,30%);
      opacity: 0.8;
      }
    }
    [data-aos="third-image"] {
      position: absolute;
      transform: rotate(-15deg)translateX(-75%)translateY(50%);
      opacity: 0;
      transition: all 1500ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(-25%,80%);
      opacity: 0.8;
      }
    }
    [data-aos="fourth-image"] {
      position: absolute;
      transform: rotate(-15deg)translateX(-130%)translateY(50%);
      opacity: 0;
      transition: all 1500ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(-90%,105%);
      opacity: 0.8;
      }
    }
    [data-aos="fifth-image"] {

      transform: rotate(-45deg)translate(100%,-50%);
      transition: all 1000ms ease-in;
      &.aos-animate {
      transform: rotate(25deg)translate(90%,-70%);
      }
    }
    [data-aos="sixth-image"] {

      transform: rotate(-10deg)translateY(-105%)translateX(75%);
      transition: all 1000ms ease-in;
      &.aos-animate {
      transform: rotate(30deg)translateY(-35%)translateX(30%);
      }
    }
    [data-aos="seventh-image"] {

      transform: rotate(-10deg)translate(125%, 10%);
      transition: all 1500ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(110%,25%);
      }
    }
    [data-aos="eight-image"] {

      transform: rotate(-15deg)translate(35%,30%);
      transition: all 1500ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(45%,50%);
      }
    }

  }



  .ontop{
    position:relative;
    z-index:5;
  }

  
 .aos-animate{
    transform: scale(1.15) translateX(-43.5%);
  }


  .campaign-imagebanner{
    aspect-ratio:unset;
    height: 618px;

    ${mediaQueries.smDown}{
       max-height: 250px;
    }

  }

  .thank-you-big-banner {
    max-width: 1266px;
    padding: 0;
  }
 

  .shop-now-products {

    max-width: 1325px;
    margin: 50px auto 0;

    ${mediaQueries.smDown}{
      padding: 0 20px;
    }
    
    
      .product-container {
        
        color: #363139;
        text-align: center;      
        // max-width: 382px;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        // ${mediaQueries.smDown}{
        //   max-width: 335px;
        // }

        .img-container {
          max-width: 998px;
          margin: 40px auto 22px;

          img {
            object-fit: cover;
            object-position: center center;
            width: 100%;
            background-color: rgb(239, 238, 233);
            border-radius: 20px;
            display: block;
            height: 600px;
            margin-bottom: 70px;

            ${mediaQueries.smDown}{
              height: 335px;
            }
          }

        }
  
    
        .product-inner-container {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          border-radius: 12.72px;

          .link {
            color: #363139;
            font-family: "Quicksand";
            font-size: 14px;
            font-weight: bold;
          }

          h4 {
            color: #363139;
            font-family: Quicksand;
            font-size: 14px;
            text-transform: uppercase;
            margin: 20px 0 0;
          }
         
            h2 {
              font-family: "Tiempos Fine";
              font-size: 32px;
              letter-spacing: 0.5px;
              line-height: 30px;
              margin: 10px auto 0;
            }
        
            p {
              font-family: Quicksand;
              font-size: 14px;
              letter-spacing: 1px;
              line-height: 20px;
              margin-bottom: 20px;
            }
    
            a {
              min-width: 200px;
            }
        }  
      }
    }
    

  }
 

.suggestion-txt {
  max-width: 702px;
  margin: 50px auto;

  ${mediaQueries.smDown}{
    max-width: 355px;
    margin: 30px auto;
  }

  @media screen and (max-width: 320px){
    max-width: 300px;
    margin: 30px auto;
  }
  
}
 


`;

const GiftFinderPage: React.FC = () => {
  const headerRef = useRef() as MutableRefObject<HTMLElement>;
  const mainRef = useRef() as MutableRefObject<HTMLElement>;
  const sectionOneRef = useRef() as MutableRefObject<HTMLElement>;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [isCompleteFlag, setCompleteFlag] = useState(false);
  const [questionData, setQuestionData] = useState(dataQuestions);
  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState(dataAnswers);

  function closeModal() {
    document.body.style.overflow = "scroll";
    setIsOpen(false);

    const shareId = window.location.hash && window.location.hash.split('?')[1].split('=')[1];
    let result;

    if(shareId) {
      result = shareId;
    } else {
      result = localStorage.getItem("giftResult") ? localStorage.getItem("giftResult") : "";
    }

    const completionUrl = "quiz=completed?gift=" + result;

    if (result && result !== "") {

      let newVal = dataAnswers.filter((answers) => result === answers.giftIds.toString());

      setProductData(newVal);
      setIsLoading(true);
      setCompleteFlag(true);
  
      window.location.hash = completionUrl;
      window.scrollTo(0, 0);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 6000);
  }

  function openModal() {
    localStorage.clear();
    document.body.style.overflow = "hidden";
    setIsOpen(true);
  }

  useEffect(() => {
    
    const shareId = window.location.hash && window.location.hash.split('?')[1].split('=')[1];

    if (shareId) {
      closeModal()
    } else {
          window.location.hash = "";
    }

    AOS.init({
      duration: 1000,
      throttleDelay: 10,
      animatedClassName: "animateMe",
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom",
    });
    AOS.refresh();
  }, []);

  const isMobile = useMediaQuery(mediaQueries.qmd);

  const backgroundColor = () => {
    return useMediaQuery(mediaQueries.qmd)
      ? "#fff"
      : `${themeGet("colors.lightgrey")}`;
  };

  const mainIntersection = useIntersection(headerRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  });


  return (
    <ParallaxProvider>
      <Layout transparent={mainIntersection?.isIntersecting}>
        <SEO title="Gift Finder" />

        <MainStyled
          id="starPoint"
          style={{ backgroundColor: backgroundColor() }}
          ref={mainRef}
        >
          {isCompleteFlag ? (
            <React.Fragment>
              <div className="thank-you-banner">
                <h1>
                {productData[0].giftNm}
                </h1>

                <p className="gift-result-info">{productData[0].giftInfo}</p>

                <Button variant="primary" className="finder-btn">
                  Share results
                  <ShareOverlay />
                </Button>
                <Link
                  className={clsx("animated", "link introTrigger")}
                  onClick={openModal}
                >
                  Restart The Test
                </Link>
              </div>

              <div className="shop-now-products">
                  {productData?.map((answers) => {
                    return (
                      <div
                        className="product-container"
                        key={answers.giftIds}
                      >
                        <div className="product-inner-container">
                          <h2>{answers.productNm}</h2>
                          <p>{answers.giftResultInfo}</p>
                          <Link
                            className={clsx("animated", "link introTrigger")}
                            to={answers.shopUrl}
                          >
                            SHOP NOW
                          </Link>
                        </div>
                        <div className="img-container">
                          <img src={answers.url} alt={answers.giftNm} /> 
                          <p>{answers.description}</p>
                        </div>
                      </div>
                    );
                  })}
              </div>

              <section
                className={clsx(
                  "container thank-you-big-banner",
                  `${isMobile ? "mobile-container" : "container"}`
                )}
              >
                <Banner
                  style={{ height: "866px" }}
                  backgroundPosition="center 22.5%"
                  className={clsx("banner campaign-imagebanner fade")}
                  image={{
                    alt: "Gift_Finder_Result_Page",
                    src:
                      "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/blt4fc1c0406b835d63/GiftFinder_Result.png",
                  }}
                  isMobile={isMobile}
                  justifyItems="center"
                  layout="overlay"
                  iosHeight={true}
                  isAnimated={true}
                />
                <div className="e-container">
                  <span>
                  Give the gift of memories, or sample our beautiful scents, with our fragrance gift sets this festive season. 
                  </span>
                  <LinkButton
                    variant="primary"
                    className="finder-btn"
                    to="/fragrances/gift-sets/"
                  >
                    SHOP NOW
                  </LinkButton>
                </div>
              </section>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <section
                className={clsx(
                  "container banner-section",
                  `${isMobile ? "mobile-container" : "container"}`
                )}
              >
                <Banner
                  backgroundPosition="center center"
                  className={clsx("banner campaign-imagebanner fade")}
                  image={{
                    alt: "Gift_Finder_Landing_Page",
                    src:
                      "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/blt2d20f5067708d260/GiftFinder_landing.png",
                  }}
                  isMobile={isMobile}
                  justifyItems="center"
                  layout="hero"
                  text="Are you on the lookout for a thoughtful gift this festive season?"
                  iosHeight={true}
                  title="Gift Finder"
                  isAnimated={true}
                />
              </section>
              <div className="gift-finder-container ontop" id="parent">
                <section
                  className={clsx("section-bottle-figure")}
                  ref={sectionOneRef}
                >
                  <Button variant="primary" onClick={openModal}>
                    Find My Gift
                  </Button>
                  <p className={clsx("animated")}>
                  When it comes to finding something out of the ordinary that you know they'll truly adore, it can be tricky to choose a suitable present.
                  </p>
                  <p className={clsx("animated")}>
                  That's why we came up with this effortless quiz. Just answer seven simple questions about your giftee’s festive personality, and we'll do the rest. We'll present you with a gift recommendation tailored to their tastes – so that you know they'll treasure it forever.
                  </p>
                </section>
              </div>
            </React.Fragment>
          )}
        </MainStyled>
      </Layout>
      <ModalGiftFinder
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        questions={questionData}
      />

      {isLoading ? (
        <LoaderStyled>
          <div className="overlay">
            <div className="overlay__inner">
              <div className="overlay__content">
                <span className="spinner"></span>
                <div className="loading" data-loading-text="La Perla..."></div>
              </div>
            </div>
          </div>
        </LoaderStyled>
      ) : null}
    </ParallaxProvider>
  );
};

export default GiftFinderPage;
