const ASSET_BASE_URL =
  "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/";

export const dataQuestions = [
  {
    question: "Are you shopping for yourself or someone else?",
    question2: "",
    description: "",
    description2: "",
    type: "checkbox",
    choices: [
      { value: "for me", points: 1, perfumeIds: [0] },
      { value: "for someone else", points: 1, perfumeIds: [0] },
    ],
  },
  {
    question: "Which colour best reflects you?",
    question2: "Which colour best reflects her?",
    description: "",
    description2: "",
    type: "color",
    choices: [
      { value: "Yellow", code: "#FFDD68", points: 1, perfumeIds: [2, 8] },
      { value: "Red", code: "#B74539", points: 1, perfumeIds: [4, 6, 7, 9] },
      { value: "Pink", code: "#FEDDDD", points: 1, perfumeIds: [2, 3, 9] },
      { value: "Purple", code: "#610264", points: 1, perfumeIds: [5, 7] },
      { value: "Blue", code: "#143789", points: 1, perfumeIds: [3, 4, 8] },
      { value: "Green", code: "#BFCEA5", points: 1, perfumeIds: [5, 8, 9] },
      { value: "Black", code: "#000000", points: 1, perfumeIds: [1, 6, 7] },
      { value: "White", code: "#FFFFFF", points: 1, perfumeIds: [1, 2, 3] },
    ],
  },
  {
    question:
      "Do you feel most energised in the morning – or do you come alive at night? ",
    question2:
      "Is she most energised in the morning, or does she come alive at night? ",
    description: "",
    description2: "",
    type: "checkbox",
    choices: [
      { value: "Early Bird", points: 1, perfumeIds: [1, 2, 5, 7, 8, 9] },
      { value: "Night owl", points: 1, perfumeIds: [1, 3, 4, 6] },
      {
        value: "DEPENDS ON THE DAY!",
        points: 1,
        perfumeIds: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      },
    ],
  },
  {
    question:
      "Which facet of your personality would you like your new fragrance to emphasise?",
    question2:
      "Which facet of her personality do you think her new fragrance should emphasise? ",
    description: "",
    description2: "",
    type: "checkbox",
    choices: [
      { value: "Confident & Poised", points: 1, perfumeIds: [1, 5, 9] },
      { value: "Fearless & Adventurous", points: 1, perfumeIds: [4, 6] },
      { value: "Chatty & Playful", points: 1, perfumeIds: [2, 8] },
      { value: "Reflective & Relaxed", points: 1, perfumeIds: [1, 2, 3] },
      { value: "Mysterious & Captivating", points: 1, perfumeIds: [2, 6, 7] },

      {
        value: "DEPENDS ON THE DAY!",
        points: 1,
        perfumeIds: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      },
    ],
  },
  {
    question: "Look at the lingerie below.",
    question2: "Look at the lingerie below.",
    description:
      "If you're looking for your signature scent, choose the style that reflects what you usually love to wear. If you're looking for a special, new scent – choose the one you have always secretly wanted to try...",
    description2:
      "If you're looking for her signature scent, choose the style she most loves to wear. If you're choosing for a special occasion, pick the one that will fit the moment...",
    type: "image",
    choices: [
      {
        alt: "lingerie1Black",
        src:
          ASSET_BASE_URL +
          "blt8cef1086bc034d2e/60f57c60138d416455fdadef/Black_Lace_Valentines_InstaFeed_2021.01.20.LAPERLA_STILLLIFE1552.jpg",
        points: 1,
        perfumeIds: [1, 7],
      },
      {
        alt: "lingerie2Valentines",
        src:
          ASSET_BASE_URL +
          "blta6f8c5b3d647c853/60f57c63e9a068658d6722f0/Valentines_InstaFeed_2021.01.20.LAPERLA_STILLLIFE1522.jpg",
        points: 1,
        perfumeIds: [1, 5],
      },
      {
        alt: "lingerie3Pastels",
        src:
          ASSET_BASE_URL +
          "blt95ea0d248c72cd7d/60f57c61da22395e2cc88ec8/Prety_Pastels_2021.01.20.LAPERLA_STILLLIFE1302.jpg",
        points: 1,
        perfumeIds: [7, 9],
      },
      {
        alt: "lingerie4Lace",
        src:
          ASSET_BASE_URL +
          "blt3ddb5047a84f362f/60f57c638bb8dc5cf252180f/Lace_Body_Valentines_InstaFeed_2021.01.20.LAPERLA_STILLLIFE1600.jpg",
        points: 1,
        perfumeIds: [2, 8],
      },
      {
        alt: "lingerie5Silk",
        src:
          ASSET_BASE_URL +
          "bltfb0889ce0cbef2e7/60f57c61bc767c65981b1901/Bright_Silk_Coords_Bralette_2021.01.20.LAPERLA_STILLLIFE1255.jpg",
        points: 1,
        perfumeIds: [4, 6],
      },
      {
        alt: "lingerie6Nude",
        src:
          ASSET_BASE_URL +
          "blt8ad11ccb3c6f423b/60f57c64da22395e2cc88ecc/White_Cotton_Separates_Neutral_Nudes_2021.01.20.LAPERLA_STILLLIFE1356.jpg",
        points: 1,
        perfumeIds: [2, 3, 8],
      },
    ],
  },
  {
    question: "Which genre is music to your ears? ",
    question2: "Which genre is music to her ears? ",
    description: "",
    description2: "",
    type: "checkbox",
    choices: [
      { value: "Classic", points: 1, perfumeIds: [1, 5, 8] },
      { value: "Pop", points: 1, perfumeIds: [1, 2, 3, 9] },
      { value: "Rock", points: 1, perfumeIds: [6] },
      { value: "Jazz", points: 1, perfumeIds: [1, 5, 7, 8, 9] },
      { value: "Hip Hop", points: 1, perfumeIds: [1, 3, 7] },
      { value: "Soul", points: 1, perfumeIds: [4, 5, 6] },
    ],
  },
  {
    question: "Take a quick look at the images below.",
    question2: "Take a quick look at the images below.",
    description: "Which one are you instantly drawn to?",
    description2: "Which one most reminds you of her? ",
    type: "image",
    choices: [
      {
        alt: "Drawn1Monika",
        src:
          ASSET_BASE_URL +
          "blt7728af5de780eedb/60f5826de9a068658d672322/monika-kozub-9Evqbiy9Lj8-unsplash.jpg?",
        points: 1,
        perfumeIds: [2, 4],
      },
      {
        alt: "Drawn2Moon",
        src:
          ASSET_BASE_URL +
          "blt83baafd106846fb8/60f58266e9a068658d67231e/406887847-huge.jpg",
        points: 1,
        perfumeIds: [3, 4, 6],
      },
      {
        alt: "Drawn3Dom",
        src:
          ASSET_BASE_URL +
          "blt62c3cc9e74acc321/60f58269bc767c65981b1945/dom-aguiar-eoR0Ou7js5E-unsplash.jpg",
        points: 1,
        perfumeIds: [1, 2],
      },
      {
        alt: "Drawn4Cheers",
        src:
          ASSET_BASE_URL +
          "blt0d07454da266f70f/60f5826c6c1bfa5e2d66d102/GettyImages-1220119329.jpg",
        points: 1,
        perfumeIds: [1, 8],
      },
      {
        alt: "Drawn5Dried",
        src:
          ASSET_BASE_URL +
          "blt9d3b9e04512149f8/60f58274e956315cf3128d39/shutterstock_75459877.jpg",
        points: 1,
        perfumeIds: [3, 7, 9],
      },
      {
        alt: "Drawn6Shadow",
        src:
          ASSET_BASE_URL +
          "blta44d18795cc61f0c/60f582778bb8dc5cf252184d/shutterstock_766489615.jpg",
        points: 1,
        perfumeIds: [1, 7, 9],
      },
      {
        alt: "Drawn7Pose",
        src:
          ASSET_BASE_URL +
          "blt27f6cde240f05cd0/60f58276e9a068658d672326/shutterstock_184350650.jpg",
        points: 1,
        perfumeIds: [1, 5, 8],
      },
      {
        alt: "Drawn8Dance",
        src:
          ASSET_BASE_URL +
          "blt66e1c7314c5fd2e4/60f5826bcffb376456763a8a/GettyImages-760316789.jpg",
        points: 1,
        perfumeIds: [4, 6],
      },
    ],
  },
  {
    question:
      "If you could whisk yourself away this instant, where would you go?",
    question2:
      "If she could be whisked away in an instant, where would she prefer to go?",
    description: "",
    description2: "",
    type: "image",
    choices: [
      {
        alt: "Instant1",
        src:
          ASSET_BASE_URL +
          "blt130e5357e533a21d/60f58529e9a068658d672368/shutterstock_1395773429.jpg",
        points: 1,
        perfumeIds: [8],
      },
      {
        alt: "Instant2",
        src:
          ASSET_BASE_URL +
          "blt83b4c63a2a7bf630/60f5851f138d416455fdae23/dreamstimemaximum_176886439.jpg",
        points: 1,
        perfumeIds: [2],
      },
      {
        alt: "Instant3",
        src:
          ASSET_BASE_URL +
          "blt0bfe0fc8d75c3127/60f5852ccffb376456763ab2/xiaolong-wong-6shgZ6WcnoQ-unsplash.jpg",
        points: 1,
        perfumeIds: [3, 9],
      },
      {
        alt: "Instant4",
        src:
          ASSET_BASE_URL +
          "bltd936ff7e1494f06e/60f5851ccffb376456763aae/cecile-hournau-ZmeOcrsECVg-unsplash.jpg",
        points: 1,
        perfumeIds: [4],
      },
      {
        alt: "Instant5",
        src:
          ASSET_BASE_URL +
          "bltdfe4fda26c9e3a84/60f58525da22395e2cc88f32/shutterstock_153440456.jpg",
        points: 1,
        perfumeIds: [7],
      },
      {
        alt: "Instant6",
        src:
          ASSET_BASE_URL +
          "blt3bb46523d7d32898/60f58524bc767c65981b1965/iStock-495934578.jpg",
        points: 1,
        perfumeIds: [5],
      },
      {
        alt: "Instant7",
        src:
          ASSET_BASE_URL +
          "bltd0885141c293d3c2/60f58528138d416455fdae27/shutterstock_1010722126.jpg",
        points: 1,
        perfumeIds: [6],
      },
      {
        alt: "Instant8",
        src:
          ASSET_BASE_URL +
          "blte644c61248dca55f/60f585228bb8dc5cf2521867/gabriella-clare-marino-5fyZuPAJSZg-unsplash.jpg",
        points: 1,
        perfumeIds: [1],
      },
    ],
  },
  {
    question: "I want my perfume to make me feel...",
    question2: "I want her perfume to make her feel...",
    description: "",
    description2: "",
    type: "icons",
    choices: [
      {
        alt: "...pure bliss, every day",
        src:
          ASSET_BASE_URL +
          "blt73cb805eff813581/60f0306eb2423f17996d7b96/Hearts.svg",
        points: 1,
        perfumeIds: [1, 2, 3, 4, 5, 8, 9],
      },
      {
        alt: "...fiercely feminine, with all the power that comes with it",
        src:
          ASSET_BASE_URL +
          "blt659a2460563ca27e/60f5866b138d416455fdae35/Fierce.svg",
        points: 1,
        perfumeIds: [1, 5, 6, 7],
      },
      {
        alt: "...full of renewed confidence, ready for new adventures",
        src:
          ASSET_BASE_URL +
          "blta52046492f22c7f3/60f5866b8bb8dc5cf2521881/Adventures.svg",
        points: 1,
        perfumeIds: [1, 3, 5, 7],
      },
      {
        alt: "...as bright and warm as the sun, full of optimism",
        src:
          ASSET_BASE_URL +
          "bltbf1368010163ae55/60f0306f97d708179361ac0b/Bright.svg",
        points: 1,
        perfumeIds: [5, 8, 9],
      },
      {
        alt:
          "...as if fresh from the shower, and cocooned in comforting cashmere",
        src:
          ASSET_BASE_URL +
          "blt1c7099c37674e16f/60f5866bbc767c65981b197f/Drops.svg",
        points: 1,
        perfumeIds: [2],
      },
      {
        alt: "...alluringly sensual, and ready to seduce",
        src:
          ASSET_BASE_URL +
          "blt9626cb62477c7bd5/60f0306f24f3901c1c1f426c/Lips.svg",
        points: 1,
        perfumeIds: [3, 4, 6, 7, 9],
      },
      {
        alt: "...like the passionate rhythm of an intimate dance",
        src:
          ASSET_BASE_URL +
          "blt5ada63f2a944b88b/60f0306f050bf616505a9937/Music.svg",
        points: 1,
        perfumeIds: [6],
      },
    ],
  },
];

export const dataAnswers = [
  {
    perfumeId: 1,
    perfumeNm: "La Perla",
    shopUrl: "/la-perla-signature-90ml-eau-de-parfum/",
    url:
      ASSET_BASE_URL +
      "blta44ac56564e06f20/60acc9e69f75ec3509386477/00008753999001_SIGNATURE_1.jpg?disposition=inline",
    description: `An elegant floral bouquet, as radiant, layered and confident as the woman who wears it.`,
  },
  {
    perfumeId: 2,
    perfumeNm: "Invisible Touch",
    shopUrl: "/invisible-touch-120ml-eau-de-parfum/",
    url:
      ASSET_BASE_URL +
      "bltd2ce929e20c1c8d3/609fefb785e84d4f539ed6eb/InvisibleTouch3-2.jpg?disposition=inline",
    description: `Do you want to capture that amazing feeling of La Perla lingerie caressing your skin in a scent?`,
  },
  {
    perfumeId: 3,
    perfumeNm: "Once Upon a Garden",
    shopUrl: "/once-upon-a-garden-120ml-eau-de-parfum/",
    url:
      ASSET_BASE_URL +
      "bltdd19727eb82448f6/609fefaa4409d451a6828183/OnceUponAGarden_3-2.jpg?disposition=inline",
    description: `A garden path leading you into a dream...`,
  },
  {
    perfumeId: 4,
    perfumeNm: "About That Night",
    shopUrl: "/about-that-night-120ml-eau-de-parfum/",
    url:
      ASSET_BASE_URL +
      "blt80a56abb892233e5/60a5e7f7245d3e39dcaa107a/ATN_3-2.jpg?disposition=inline",
    description: `Are you ready to be taken back to that delectable night, in all its most vivid detail?`,
  },
  {
    perfumeId: 5,
    perfumeNm: "My Day",
    shopUrl: "/my-day-120ml-eau-de-parfum/",
    url:
      ASSET_BASE_URL +
      "bltc88a9e9cf0148a5c/609fefa8821a2750878ad13d/MyDay_3-2.jpg?disposition=inline",
    description: `That priceless feeling of achievement– it’s your moment, enjoy it.`,
  },
  {
    perfumeId: 6,
    perfumeNm: "Let the Dance Begin",
    shopUrl: "/let-the-dance-begin-120ml-eau-de-parfum/",
    url:
      ASSET_BASE_URL +
      "blt75d100746fcdb95d/60a5e8256f1e0934b3e7322c/LTDB_3-2.jpg?disposition=inline",
    description: `Make your every move as thrilling as a slow dance with racing heartbeats.`,
  },
  {
    perfumeId: 7,
    perfumeNm: "Possibilities",
    shopUrl: "/possibilities-120ml-eau-de-parfum/",
    url:
      ASSET_BASE_URL +
      "bltb74e22fe5d889e46/60a5e8540cc0143b0a901f10/Possibilities_3-2.jpg?disposition=inline",
    description: `Heads turn, eyes and minds wander.`,
  },
  {
    perfumeId: 8,
    perfumeNm: "Villa Sorento",
    shopUrl: "/villa-sorrento-120ml-eau-de-parfum/",
    url:
      ASSET_BASE_URL +
      "bltd880adeae89f1968/609c8eb35057864f4d338b16/VillaSorrento_3-2.jpg?disposition=inline",
    description: `A spritz of Villa Sorrento brings you instantly back to an unforgettable summer on the sun-kissed shores of the Amalfi coast.`,
  },
  {
    perfumeId: 9,
    perfumeNm: "Just give me Roses",
    shopUrl: "/just-give-me-roses-120ml-eau-de-parfum/",
    url:
      ASSET_BASE_URL +
      "bltd5ecb8e1c9d45d7e/609fefa65f20f44da9aa02d8/JGMR_3-2.jpg?disposition=inline",
    description: `It's simple, yet utterly profound – the memory of a rose can melt your heart.`,
  },
];
