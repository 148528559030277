const ASSET_BASE_URL =
  "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/";

export const dataQuestions = [
  {
    question: "First, what does their Saturday morning look like?",
    description: "",
    type: "image",
    choices: [
      {
        alt: "Post-party brunching with friends",
        src:
          ASSET_BASE_URL +
          "blt292d81708dd0bbe4/1-Brunch-with-friends.jpg?quality=80",
        points: 1,
        giftIds: [2],
      },
      {
        alt: "An early start and a 5k run",
        src:
          ASSET_BASE_URL +
          "bltd9601f6920606200/2-5K-run_2.jpg?quality=80",
        points: 1,
        giftIds: [5,6],
      },
      {
        alt: "A lie-in complete with a cup of coffee in bed",
        src:
          ASSET_BASE_URL +
          "blte94336aeb2de838d/3-Coffee-in-bed.jpg?quality=80",
        points: 1,
        giftIds: [1],
      },
      {
        alt: "Making time for meditation and mindfullness",
        src:
          ASSET_BASE_URL +
          "bltf673467b66394379/4-Meditate.jpg?quality=80",
        points: 1,
        giftIds: [4,6],
      },
      {
        alt: "Hopping on a flight for a glamorous weekend break",
        src:
          ASSET_BASE_URL +
          "blt29c128426ddfd894/5-Hop-on-a-flight-to-a-weekend-break.jpg?quality=80",
        points: 1,
        giftIds: [3],
      },
    ],
  },
  {
    question: "Which festive film is their favourite?",
    description: "",
    type: "checkbox",
    choices: [
      { value: "The Christmas Tale (Un Conte de Noel)", points: 1, giftIds: [4] },
      { value: "Arthur Christmas", points: 1, giftIds: [5] },
      { value: "Love Actually", points: 1, giftIds: [1] },
      { value: "Bad Santa", points: 1, giftIds: [2] },
      { value: "Bridget Jones Diary", points: 1, giftIds: [6] },
      { value: "Home Alone", points: 1, giftIds: [3] },
    ],
  },
  {
    question: "What will they be wearing on New Years Eve?",
    description: "",
    type: "checkbox",
    choices: [
      { value: "The softest cashmere sweater they own", points: 1, giftIds: [1] },
      { value: "A classic little black number", points: 1, giftIds: [4] },
      { value: "A vibrant print to draw in attention", points: 1, giftIds: [3] },
      { value: "A look that's 100% vegan", points: 1, giftIds: [6] },
      { value: "Something unexpected – they always surprise you!", points: 1, giftIds: [5] },
      { value: "Anything glittering in sequins", points: 1, giftIds: [2] },
    ],
  },
  {
    question: "What's their preferred festive tipple?",
    description: "",
    type: "checkbox",
    choices: [
      { value: "A fruity cocktail", points: 1, giftIds: [3] },
      { value: "Hot cocoa", points: 1, giftIds: [1] },
      { value: "An expensive red wine", points: 1, giftIds: [4] },
      { value: "Champagne", points: 1, giftIds: [2] },
      { value: "A mocktail", points: 1, giftIds: [6] },
      { value: "Depends on the occasion", points: 1, giftIds: [5] },
    ],
  },
  {
    question: "What’s their favourite festive season tradition?",
    description: "",
    type: "checkbox",
    choices: [
      { value: "A stolen kiss under the mistletoe", points: 1, giftIds: [2] },
      { value: "Making delicious mulled wine", points: 1, giftIds: [4] },
      { value: "Decorating their home and baking festive treats", points: 1, giftIds: [5] },
      { value: "A seasonal movie marathon", points: 1, giftIds: [1] },
      { value: "Exploring festive markets", points: 1, giftIds: [3] },
      { value: "Volunteering and helping their community", points: 1, giftIds: [6] },
    ],
  },
  {
    question: "Where will they be spending the festive season?",
    description: "",
    type: "image",
    choices: [
      {
        alt: "Staying warm by a cosy fireplace",
        src:
          ASSET_BASE_URL +
          "blt9c1a74c0de9a35fa/1_fireplace.jpg?quality=80",
        points: 1,
        giftIds: [1],
      },
      {
        alt: "Enjoying some sun on the beach",
        src:
          ASSET_BASE_URL +
          "blt45d9ce5238a08786/2_beach.jpg?quality=80",
        points: 1,
        giftIds: [3],
      },
      {
        alt: "Eating out at exclusive restaurants",
        src:
          ASSET_BASE_URL +
          "blt0cfc10bd6324efcc/3_restaurant.jpg?quality=80",
        points: 1,
        giftIds: [4],
      },
      {
        alt: "Bar-hopping and socialising",
        src:
          ASSET_BASE_URL +
          "blt2453d5ef1632f655/4_barhop_2.jpg?quality=80",
        points: 1,
        giftIds: [2],
      },
      {
        alt: "Doing something different every day",
        src:
          ASSET_BASE_URL +
          "blt7d27357ab098d90b/5_different_2.jpg?quality=80",
        points: 1,
        giftIds: [5],
      },
      {
        alt: "In the great outdoors",
        src:
          ASSET_BASE_URL +
          "bltddb069af4f271c6f/6_outdoors.jpg?quality=80",
        points: 1,
        giftIds: [6],
      },
    ],
  },
  {
    question: "Who would they like to meet under the mistletoe?",
    description: "",
    type: "checkbox",
    choices: [
      { value: "Their first love or a nostalgic school sweetheart", points: 1, giftIds: [1] },
      { value: "Their current partner", points: 1, giftIds: [4,6] },
      { value: "A handsome stranger", points: 1, giftIds: [3] },
      { value: "The one that got away", points: 1, giftIds: [2] },
      { value: "They would never tell!", points: 1, giftIds: [5] },
    ],
  },
 
  
];

export const dataAnswers = [
  {
    giftIds: 1,
    giftNm: "The Homebody",
    giftInfo: "For this special someone, it’s all about keeping cosy in the cold winter weather. They love this time of year as they get to spend time with loved ones while also indulging in holiday traditions. You’ll probably find them baking sweet delights in the kitchen and singing festive songs as they decorate their home.",
    giftResultInfo: "For the person who loves wrapping up warm and cocooning at home, we recommend our Nourishing Body Balm.",
    shopUrl: "/lp-leave-on-body-balm-150ml-product/",
    url:
      ASSET_BASE_URL +
      "blte515a36ac9316733/Nourishing_Body_Balm.jpg?quality=80",
      productNm: "Nourishing Body Balm",
    description: `They can enjoy luxurious pampering with this soft and indulgent cream, which will leave their skin feeling smooth as silk.`,
  },
  {
    giftIds: 2,
    giftNm: "The Social Butterfly",
    giftInfo: "The more parties, the better. This person loves the buzz of this time of year and takes every chance they can to socialise with friends and family. If there’s even the suggestion of a party, that's where you'll find them.",
    giftResultInfo: "For a night of partying, it has to be our matte silk lipstick in Venetian Red – it's our hero shade for this festive season.",
    shopUrl: "/106-venetian-red/",
    url:
      ASSET_BASE_URL +
      "bltc1d97843b356ab2d/Matte_Silk_Lipstick_Venetian_Red.jpg?quality=80",
      productNm: "Matte Silk Lipstick",
      description: `Its exceptional staying power means that just one swipe of colour is enough to dance the night away without a care in the world.`,
  },
  {
    giftIds: 3,
    giftNm: "The Sun Seeker",
    giftInfo: "You won’t see much of this sun-seeking explorer during the festive break, as they’ll probably be off spending it somewhere new to them. They love to travel and find themselves inspired by other cultures in everything they do.",
    giftResultInfo: "The fresh citrus and floral notes of this perfume will remind the Sun Seeker of warmer climes and banish any winter blues.",
    shopUrl: "/villa-sorrento-120ml-eau-de-parfum/",
    url:
      ASSET_BASE_URL +
      "blt965837e892a3f67a/Villa_Sorrento_30ml.jpg?quality=80",
      productNm: "Villa Sorrento Eau de Parfum",
      description: `If they have travel plans during the holidays, the 30ml size of this scent will be a suitably petite addition to their hand luggage.`,
  },
  {
    giftIds: 4,
    giftNm: "The Sophisticate",
    giftInfo: "This person would much rather be indulging in a dignified dinner than having to attend the annual office party. They adore timeless elegance, and that's no different during the festive season.",
    giftResultInfo: "Our Signature La Perla Perfume is perfect for refined tastes.",
    shopUrl: "/lp-signature-orig-edp-50ml-product/",
    url:
      ASSET_BASE_URL +
      "blt55e23d461702545a/La_Perla_Signature_50ml.jpg?quality=80",
      productNm: "",
      description: `It will make a sophisticated companion to take to each of the luxurious events your recipient has planned over the holidays.`,
  },
  {
    giftIds: 5,
    giftNm: "The Chameleon",
    giftInfo: "A tough one to figure out – you’re equally likely to find this person bringing a new lover to a family reunion as you are to see them spending the season volunteering at a charity! No matter what they're doing, it’s always going to be something unexpected.",
    giftResultInfo: "What do you get for someone who’s hard to define? Our Collection Coffret, of course.",
    shopUrl: "/collection-fragrances-miniature-coffret-8-x-12ml/",
    url:
      ASSET_BASE_URL +
      "bltd6a61755b582b2d2/Product_COFFRET.jpg?quality=80",
      productNm: "Collection Fragrances Miniature Coffret",
      description: `They can experiment with layering different scents or simply try a new one each day to represent the many facets and shades of their personality.`,
  },
  {
    giftIds: 6,
    giftNm: "The Wellness Guru",
    giftInfo: "Even throughout the festivities, you’ll find this dedicated fitness enthusiast outdoors keeping healthy. However, it doesn’t stop them from indulging here and there – it’s all about balance, after all.",
    giftResultInfo: "Our Energising Salt and Oil Body Scrub is ideal post workout.",
    shopUrl: "/lp-wash-off-shower-oil-200ml-product/",
    url:
      ASSET_BASE_URL +
      "blt9a3b777d02ffc434/Product_BODY_SCRUB.jpg?quality=80",
      productNm: "Energising Body Scrub",
      description: `Bringing a spa-like experience to any bathroom, it’s vegan and formulated with naturally exfoliating sea salt suspended in a blend of carefully selected moisturising oils.`,
  },

];
