import { themeGet } from '@styled-system/theme-get';
import React, { useEffect } from "react"
import styled from 'styled-components';
import { LinkButton } from '../../components/Button';
import { IconList } from '../../components/IconList';
import { Layout } from '../../components/Layout';
import { SEO } from '../../components/SEO';
import { SignInForm } from '../../components/SignInForm';
import { mediaQueries } from '../../theme';

const MainStyled = styled.main`

    max-width: 1266px;
    margin: 0 auto;  

    ${mediaQueries.md} {
      margin: 70px auto;  
    }

  input::selection {
    color: white;
    background: #3483e3;
  }

  display: grid;
  place-content: center;

  .desktop-heading {
    display: none;

    ${mediaQueries.md} {
      display: initial;
      font-size: 32px; //${themeGet('fontSizes.8')}px;
      //margin-block-start: 70px;
    }
  }

  .mobile-heading {
    font-size: 24px; //var(--font-size-xl, 24px);
    margin-top:30px;
    margin-bottom: 10px;
    font-weight: 500;

    ${mediaQueries.md} {
      display: none;
    }

    & + p{
      margin-top: 0;
      margin-bottom: 0;
      font-size: 13px;
      line-height: 17px;
      color: #363139;
      font-family: "Quicksand", sans-serif;
      letter-spacing: 1px;

      ${mediaQueries.md} {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  form{

    input[type=email]{
      margin-bottom: 8px;
    }

    input[type=password]{
      margin-bottom: 5px;
    }

    .information{
      margin-bottom: 18px;

      a{
        font-family: "Quicksand", sans-serif;
        font-size: 11px;
        letter-spacing: 0.7px;

        ${mediaQueries.md} {
          font-size: 12px;
          letter-spacing: 0.8px;
        }
      }
    }
  }

`;

const GridStyled = styled.section`
  display: grid;
  gap: ${themeGet('space.9')}px;
  grid-auto-flow: row;
  margin-block-start: 12px;

  ${mediaQueries.md} {
    gap: 6rem;
    grid-template-columns: minmax(400px, 550px) minmax(400px, 550px);
    margin-block-end: 70px;
    margin-block-start: 1rem;
    place-content: center;
  }

  .sign-in {
    display: grid;
    gap: 1rem;
    grid-auto-flow: row;

    ${mediaQueries.md} {
      display: flex;
      flex-direction: column;
    }

    h1 {
      font-size: 24px; //var(--font-size-lg, 18px);
      margin: 0;
    }

    .desktop-subheading {
      display: none;

      ${mediaQueries.md} {
        display: initial;
        font-size: var(--font-size-lg, 18px);
        margin: 0;
      }
    }

  
    p {
      margin: 0;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 1px;
      margin-bottom: 30px;
    }

    form {
      gap: 0;

      span {
        font-size: var(--font-size-sm, 12px);
        text-transform: uppercase;

        ${mediaQueries.md} {
          font-size: ${themeGet('fontSizes.1')}px;
        }
      }

      .input-container{
        position: relative;
        margin-bottom: 20px;

        & + .input-container{
          margin-bottom: 0;

          input{
            padding-right: 40px;
          }
        }

        label{
          margin-bottom: 10px;
          font-size: 13px;
          letter-spacing: 1px;
        }

        .password-container{
          position: relative;
        }

        input{
          margin-bottom: 0;
          font-size: 13px;
          letter-spacing: 1px;
          width: 100%;
          position: relative;
          border: 1px solid #363139;

          &:placeholder-shown{
            border: 1px solid #efeee9;
          }
        }

        .show-password{
          background: url("../icons/eye-icon.svg") center center no-repeat;
          width: 20px;
          height: 12px;
          position: absolute;
          right: 15px;
          top: 50%;
          transform: translateY(-50%);

          &.hide{
            background: url("../icons/eye-hide-icon.svg") center center no-repeat;
          }
        }

        .error{
          margin-top: 10px;
          font-size: 11px;
        }
      }

      .information{
        margin-top: 20px;
        margin-bottom: 30px;
      }

      .bySigning{
        margin-bottom: 10px;
      }
    }
  }
  .register {
    display: grid;
    gap: ${themeGet('space.7')}px;
    grid-auto-flow: row;
    align-items: flex-start;
    h1 {
      font-size: 24px;
      letter-spacing: 0.5px;
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  .confirmation{
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 1px;
    margin-bottom: 30px;
  }
`;

const AccountPage: React.FC = () => {

  useEffect(() => {
    let $showHidePassword = document.querySelector(".show-password");
    let $passwordInput = document.querySelector(".password-container input");
  
    $showHidePassword.addEventListener("click", function(){
      this.classList.toggle('hide');
      

      if ($passwordInput.getAttribute("type") == "password") {
        $passwordInput.setAttribute("type", "text");
      } else {
        $passwordInput.setAttribute("type", "password");
      }
    });
  })

  return (
    <Layout>
      <SEO title="Account" />
      <MainStyled className="container">
        <h1 className="desktop-heading">Sign in/Register</h1>
        <h1 className="mobile-heading">Sign in/Register</h1>

        {process.env.GATSBY_ENV?.toLowerCase().includes("us") && (
          <p>
            We hope this is the start of something special…
            <br />
            <br />
            If you like what you've seen so far, why not create an account? Join
            us, and other La Perla women around the world, on our mission to
            uncover true beauty because confidence is beautiful.
          </p>
        )}

        {!process.env.GATSBY_ENV?.toLowerCase().includes("us") && (
          <p>
            We hope this is the start of something special…
            <br />
            <br />
            If you like what you've seen so far, why not create an account? Join
            us, and other La Perla women the world over, in exploring and
            celebrating the many layers of beauty and femininity – because
            confidence is beautiful.
          </p>
        )}

        <GridStyled>
          <section className="sign-in">
            <h1>Login</h1>
            <SignInForm />
          </section>
          <section className="register">
            <h1>Account Benefits</h1>
            <IconList
              items={[
                {
                  colour: "lightgreen",
                  icon: "plant",
                  text:
                    "Easily communicate with us and access your order history.",
                },
              ]}
              mobileView="stack"
              orientation="horizontal"
            />
            <IconList
              items={[
                {
                  colour: "pink",
                  icon: "diamond",
                  text: "Pre-order the newest products",
                },
              ]}
              mobileView="stack"
              orientation="horizontal"
            />
            <IconList
              items={[
                {
                  colour: "beige",
                  icon: "perfume",
                  text:
                    "Keep an eye on your most desired items with a wishlist",
                },
              ]}
              mobileView="stack"
              orientation="horizontal"
            />
            <IconList
              items={[
                {
                  colour: "lilac",
                  icon: "envelope",
                  text:
                    "Sign up to our emails, to be the first to hear about exclusive beauty offers, product launches and insider tips and previews.",
                },
              ]}
              mobileView="stack"
              orientation="horizontal"
            />
            <IconList
              items={[
                {
                  colour: "orange",
                  icon: "bow",
                  text: `Save your details, and you'll be checking out in a mere moment.`,
                },
              ]}
              mobileView="stack"
              orientation="horizontal"
            />
            <LinkButton to="/account/sign-up" variant="primary" py={5}>
              register
            </LinkButton>
          </section>
        </GridStyled>
      </MainStyled>
    </Layout>
  )
};

export default AccountPage;