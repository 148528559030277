import type {
  Image as ImageProp,
  Link as LinkProp
} from "../../types/components"

import { themeGet } from "@styled-system/theme-get"
import clsx from "clsx"
import React, { useState } from "react"
import styled from "styled-components"
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps
} from "styled-system"

import { Link } from "./Button"

import ReactCardFlip from "react-card-flip"
import { VideoPlayer } from "./VideoPlayer"
import { ImgOptimized } from "./ImgOptimized"




const BlurCardStyled = styled.article`
  border-radius: ${themeGet("radii.4")}px;
  -webkit-border-radius: ${themeGet("radii.4")}px;
  @media screen and (max-width: 460px){
    border-radius: 10px;
    -webkit-border-radius: 10px;
  }
  min-height: 400px;
  overflow: hidden;
  position: relative;
  
  .react-card-flip {
    margin-top: 15%;
    margin-bottom: 15%;
    height: 80%;
    width: 100%;
    aspect-ratio: 4/5;
    aside {
      aspect-ratio: 4/5;
    }
    iframe {
      border: none;
      pointer-events: none;
    }
  }

  .main-banner-container {
    overflow: hidden;
    height: 100%;
    border-radius: ${themeGet("radii.4")}px;
    -webkit-border-radius: ${themeGet("radii.4")}px;
    @media screen and (max-width: 460px){
    border-radius: 10px;
    -webkit-border-radius: 10px;
    }
    figure {
      overflow: hidden;
      height: 100%;
      aside {
        height: 100%;
        div {
          height: 100%;
          div {
            height: 100%;
            video {
                border-radius: ${themeGet("radii.4")}px;
                -webkit-border-radius: ${themeGet("radii.4")}px;
                @media screen and (max-width: 460px){
                border-radius: 10px;
                -webkit-border-radius: 10px;
              }
            }
          }
        }
      }
    }
  }

  .main-banner-container-figure {
    figure: hidden;
  }

  img {
    background-color: ${themeGet("colors.black")};
    filter: blur(10px);
  }

  .content {
    align-content: space-between;
    bottom: 0;
    display: grid;
    justify-content: center;
    left: 0;
    padding: ${themeGet("space.11")}px 20px;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 5;

    * {
      color: ${themeGet("colors.white")};
    }

    a {
      font-weight: bold;
      text-transform: uppercase;
    }

    .title {
      font-size: var(--font-size-heading-1, 24px);
      margin: 0 auto;
    }
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`

export type BlurCardProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
  image: ImageProp
  link: LinkProp
  tag: string
  text: string
  title: string
  isVideo?: boolean
}

export const BlurCard: React.FC<BlurCardProps> = props => {

  const [flip, setFlipped] = useState(false)
  const [videoKey, setVideoKey] = useState(Date.now())
  const delayedRebuildPlayer = () => setTimeout(()=> setVideoKey(Date.now()), 300 );
  const handleClick = () => {
    if(flip) {
      delayedRebuildPlayer();
    }
    return setFlipped(state => !state)
  };

  
  return (
    <BlurCardStyled {...props}>
      {props.isVideo ? <ReactCardFlip isFlipped={flip} flipDirection="horizontal" flipSpeedBackToFront={0.6} flipSpeedFrontToBack={0.6}>
        <div className={clsx("main-banner-container")} onClick={handleClick}>
          {props.image && (
            <figure aspect-ratio="2/3">
              <ImgOptimized
                alt={props.image?.title}
                className="img-bl"
                src={props.image?.src}
              />
            </figure>
          )}
          <div className={clsx("content")}>
            {props.link && (
              <Link to={props.link?.href}>{props.link?.title}</Link>
            )}
            {props.title && <h2 className="title">{props.title}</h2>}
            {props.tag && <span className="tag">{props.tag}</span>}
          </div>
        </div>

        <div className={clsx("main-banner-container")} onClick={handleClick}>
          {props.videoUrl && (
            <figure>
              <VideoPlayer
                key={videoKey}
                alt="something"
                muted={true}
                url={props.videoUrl}
                playing={flip}
                onEnded={() => {setFlipped(false); delayedRebuildPlayer();}}
              />
            </figure>
          )}
        </div>
      </ReactCardFlip> : 
      <div>
          {props.image && (
            <figure aspect-ratio="2/3">
              <ImgOptimized
                alt={props.image?.title}
                className="img-bl"
                src={props.image?.src}
              />
            </figure>
          )}
          <div className={clsx("content")}>
            {props.link && (
              <Link to={props.link?.href}>{props.link?.title}</Link>
            )}
            {props.title && <h2 className="title">{props.title}</h2>}
            {props.tag && <span className="tag">{props.tag}</span>}
          </div>
        </div>
      }
      
    </BlurCardStyled>
  )
}

