import type {
  Contentstack_Pages,
  IngredientsPageQuery,
} from "../../graphql-types";

import { themeGet } from "@styled-system/theme-get";
import clsx from "clsx";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { PageProps, graphql } from "gatsby";
import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";

import { Breadcrumb } from "../components/Breadcrumb";
import { Button } from "../components/Button";
import { Ingredient, IngredientProp } from "../components/Ingredient";
import { Layout } from "../components/Layout";
import { PageSections } from "../components/PageSections";
import { SEO } from "../components/SEO";

import { mediaQueries } from "../theme";

type PageContextPage = PageContextTypeBreadcrumb & {
  page: Contentstack_Pages;
};

const ProductSelectorFormStyled = styled(Form)`
  max-width: 500px;
  padding-block-end: ${themeGet("space.10")}px;
  padding-block-start: ${themeGet("space.6")}px;

  ${mediaQueries.md} {
    padding-block-end: ${themeGet("space.13")}px;
    padding-block-start: ${themeGet("space.10")}px;
  }

  label {
    font-size: var(--font-size-body, 13px);
  }
`;

const MainStyled = styled.main`
  display: grid;
  gap: 1rem;

  .load-button {
    margin-bottom: 2rem;
    color: #363139;
    font-family: Quicksand;
    font-weight: bold;
    padding-left: 5%;
    padding-right: 5%;
  }
`;

const Letter = styled.dt`
  padding-block-end: ${themeGet("space.3")}px;
  padding-block-start: ${themeGet("space.3")}px;
  background-color: ${themeGet("colors.lightgreen")};
  position: sticky;
  top: 45px;
  z-index: 10;

  ${mediaQueries.md} {
    padding-block-end: ${themeGet("space.5")}px;
    padding-block-start: ${themeGet("space.5")}px;
    top: var(--header-min-height, 38px);
  }

  span {
    font-size: var(--font-size-lg, 18px);
    font-family: "Tiempos", serif;
  }
`;

const IngredientList = styled.dl`
  dd {
    &:not(:last-child) {
      border-bottom: 1px solid ${themeGet("border.color")};
    }
  }
`;

interface Values {
  product: string | undefined;
}

type OldListings = {
  [key: string]: IngredientProp[];
};

type Listings = {
  [key: string]: { letter: string; children: IngredientProp[] };
};

const productsBlacklistIds = [
  "a7b3abd7-3121-5fe6-8d10-2ac8448a1065",
  "f38bc64f-eedd-51b5-af16-bc39412aaa9d",
  "8188b8e4-07b5-584c-84a0-3255c01929f1",
  "95687682-a44d-5b8b-9634-1984c11422a5",
  "bdc9e382-01cc-51db-a8e8-0381935c10fe",
  "9b85957e-c5b2-5943-bf94-191b731e3b32",
  "f1c27143-17dd-5b60-8f29-d04d660665e5",
  "373771f5-3de6-56e2-a141-85eeec94545b",
  "a089e08d-75c3-571d-9dbb-bf978229fbe5",
];

const IngredientsPage: React.FC<
  PageProps<IngredientsPageQuery, PageContextPage>
> = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
    page,
  } = pageContext;

  const products = data?.allContentstackProduct?.edges
    ?.map(({ node }) => node)
    .filter(product => productsBlacklistIds.indexOf(product.id) === -1)
    .sort((a, b) => a.sorting - b.sorting);

  const ingredients = data?.allContentstackIngredient?.edges
    ?.map(({ node }) => node)
    .sort((a, b) =>
      a.sorting === null ? 1 : b.sorting === null ? -1 : a.sorting === b.sorting ? 0 : a.sorting < b.sorting ? -1 : 1
    ); // sorting to pull null on the last row 

  let getListings = (productId?: string | undefined) => {

    let filteredIngredients = ingredients.filter(ingredient => {
      if (productId) {
        const product = products.find(product => product.id === productId);

        const productIngredientIds = product.ingredients?.flatMap(
          ({ ingredient: ingredients }) => ingredients?.map(({ id }) => id)
        );
        
        if (productIngredientIds.includes(ingredient.id)) {
          return true;
        }

        return false;
      }

      return true;
    });

    //Old reduce
    //  .reduce((result, ingredient) => {
    //       // get the first letter. (this assumes no empty ingredients in the list)

    //       // console.log("ingredient", ingredient);
    //       const letter = ingredient.sorting?.[0];
    //       // console.log("letter", letter);

    //       // ensure the result has an entry for this letter
    //       result[letter] = result[letter] || [];
    //       // console.log("result[letter]", result[letter]);

    //       // add the ingredient to the letter index
    //       result[letter].push(ingredient);

    //       // return the updated result
    //       return result;
    //     }, {}) as Listings;

  //Old Key initialization
    // let counter = 0;
    // for (const [key, definitions] of Object.entries(listings)) {
    //   for (const definition of definitions) {
    //     definition.key = counter++;
    //   }
    // }

    let listings = filteredIngredients.reduce((result, ingredient) => {
      // get the first letter. (this assumes no empty ingredients in the list)
      const letter = ingredient.sorting?.[0];

      // if there is no property in accumulator with this letter create it
      if (!result[letter]) {
        result[letter] = { letter, children: [ingredient] };
      } else {
          // if there is push current element to children array for that letter
        result[letter].children.push(ingredient);
      }

      // return the updated result
      return result;
    }, {}) as Listings;


    let tempListing = Object.values(listings).sort((a, b) => !/^[a-zA-Z]+$/.test(a.letter) ? 1 : !/^[a-zA-Z]+$/.test(b.letter) ? -1 : a.letter === b.letter ? 0 : a.letter < b.letter ? -1 : 1 );

     // removal of undefined (expectation no null or undefined value)
    tempListing.forEach((val, i) => {
      if (val.letter === undefined) {
        delete tempListing[i];
      }
    });


    let counter = 0;
    for (const definitions of tempListing) {
      // console.log("definitionsl:", definitions);
      if(definitions) {
        for (const definition of definitions.children) {
          definition.key = counter++;
        }
      }
    }


    return tempListing;
  };

  const allListings = getListings();
  // setFilteredListings(allListings)

  // useLayoutEffect(() => {
  //   // console.log("selectedProduct", selectedProduct)

  //   // if (selectedProduct) {
  //   //   const matchingListings = getListings(selectedProduct)
  //   //   console.log("matchingListings", matchingListings)
  //   //   setFilteredListings(matchingListings)
  //   // }

  //   const listings = getListings(selectedProduct)
  //   setFilteredListings(listings)
  // })

  // const [selectedProduct, setSelectedProduct] = useState<string | undefined>(undefined)
  const [filteredListings, setFilteredListings] = useState(allListings);

  const [amountToDisplay, setAmountToDisplay] = useState<number>(10);
  const [showLoadMoreButton, setShowLoadMoreButton] = useState<boolean>(true);
  const [productIdParams, setProductIdParams] = useState("");

  useEffect(() => {

    if (typeof window !== "undefined") {
      const prodId = new URL(window.location.href).searchParams.get("prodId");

      if (prodId) {
        setProductIdParams(prodId);
      }
      console.log("ingredient productIdParams: ", productIdParams);
    }
  
  });




  return (
    <Layout>
      <SEO title={page?.title} />

      {data?.contentstackPage?.breadcrumb && <Breadcrumb crumbs={crumbs} />}

      <PageSections sections={data?.contentstackPage?.sections} />
      <header className={clsx("container", "product-selector")}>
       {productIdParams && <Formik
          initialValues={{
            product: productIdParams
          }}
          onSubmit={async (
            values: Values,
            { setSubmitting }: FormikHelpers<Values>
          ) => {
            setSubmitting(false);

            // console.log("values", values)

            const product =
              values?.product !== "all" ? values?.product : undefined;
            // console.log("product", product)

            // setSelectedProduct(values?.product)
            const matchingListings = getListings(product);
            setFilteredListings(matchingListings);
          }}
        >
          <ProductSelectorFormStyled>
            <label htmlFor="product">product selector</label>
            <Field
              className="select"
              component="select"
              id="product"
              name="product"
              onChange={e => {
                console.log("productse", products)
                console.log("e.target.value", e.target.value)

                const product =
                  e.target.value !== "all" ? e.target.value : undefined;

                if (e.target.value === "all") {
                  setShowLoadMoreButton(true);
                  setAmountToDisplay(10);
                } else {
                  setShowLoadMoreButton(false);
                }

                const matchingListings = getListings(product);
                setFilteredListings(matchingListings);
              }}
            >
              {/* <option disabled selected>
                Select a product
              </option> */}
              <option selected value="all">
                All
              </option>
              {products?.map(product => {
                // console.log("product.ingredients", product.ingredients)
                return (
                  <option key={product.id} value={product.id}>
                    {product.title}
                  </option>
                );
              })}
            </Field>
            <Button
              className="sr-only"
              type="submit"
              variant="secondary"
              py={{ md: 4 }}
              px={{ md: 9 }}
            >
              Filter
            </Button>
          </ProductSelectorFormStyled>
        </Formik>}
      </header>

      <MainStyled>
        <IngredientList>
          {filteredListings &&
            filteredListings
              .filter( (definitions) => !showLoadMoreButton || (definitions.children[0].key !== undefined && definitions.children[0].key < amountToDisplay) )
              .map((definitions) => (
                <React.Fragment key={definitions.letter}>
                  <Letter>
                    <span className="container">{definitions.letter}</span>
                  </Letter>
                  {(definitions.children as IngredientProp[])?.filter( definition => !showLoadMoreButton || (definition.key !== undefined && definition.key < amountToDisplay) )
                    .map(definition => (
                      <dd className="container" key={definition.key}>
                        <Ingredient ingredient={definition} />
                      </dd>
                    ))}
                </React.Fragment>
              ))}
        </IngredientList>
        {showLoadMoreButton && (
          <Button
            className="load-button"
            variant="tertiary"
            py="5"
            px="7"
            justifySelf="center"
            onClick={() => setAmountToDisplay(amountToDisplay + 10)}
          >
            Load more
          </Button>
        )}
      </MainStyled>
    </Layout>
  );
};

export default IngredientsPage;

export const query = graphql`
  query IngredientsPage($contentstackId: String!, $locale: String) {
    allContentstackIngredient(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          ...Contentstack_ingredientFragment
        }
      }
    }
    allContentstackProduct(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          title
          sorting
          ingredients {
            ingredient {
              id
              title
              url
            }
          }
        }
      }
    }
    contentstackPage(id: { eq: $contentstackId }) {
      ...Contentstack_pageFragment
    }
  }
`;
