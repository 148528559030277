import type { Offer, Product } from "schema-dts";

import { themeGet } from "@styled-system/theme-get";
import React from "react";
import styled from "styled-components";
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system";

import { useToggle } from "../hooks/useToggle";

import { ReactComponent as MinusIcon } from "../../static/icons/Minus.svg";
import { ReactComponent as PlusIcon } from "../../static/icons/Plus.svg";

import { mediaQueries } from "../theme";

import { Button, Link } from "./Button";
import { Price } from "./Price";
import { QuickBuy } from "./QuickBuy";
import { QuickWishlist } from "./QuickWishlist";
import { ItemAvailabilityLabel } from "./ItemAvailabilityLabel";
import { ImgOptimized } from "./ImgOptimized";
import { gtmEventEcommerceClick } from "../utils/gtm";
import { Helmet } from "react-helmet";

const ProductCardStyled = styled.article`
  align-content: space-between;
  background-color: none;
  display: grid;
  gap: ${themeGet("space.5")}px;

  .image-container,
  img {
    background-color: ${themeGet("colors.lightgrey")};
    border-radius: ${themeGet("radii.4")}px;
    display: block;
  }

  .image-clickable {
    cursor: pointer;
  }

  img {
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  .product-primary {
    display: grid;
    gap: ${themeGet("space.3")}px;
    grid-template-rows: min-content 1fr;
  }

  .product-information {
    align-items: center;
    display: grid;
    gap: 0.5rem;
    grid-auto-flow: column;
    justify-content: space-between;
  }

  .product-category-wrapper {
    align-items: center;
    display: grid;
    gap: 0.5rem;
    grid-auto-flow: column;
    justify-content: end;
  }

  .product-actions {
    align-items: center;
    display: grid;
    grid-auto-flow: column;

    ${mediaQueries.sm} {
      gap: ${themeGet("space.3")}px;
    }

    button {
      padding-inline-end: ${themeGet("space.2")}px;
      padding-inline-start: ${themeGet("space.2")}px;
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  .product-category {
    color: inherit;
    font-size: var(--font-size-sm, 12px);
    letter-spacing: 1px;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;

    ${mediaQueries.sm} {
      overflow: unset;
      text-overflow: unset;
      white-space: unset;
    }
  }

  .product-name {
    align-self: flex-end;
    font-family: "Tiempos", serif;
    font-size: var(--font-size-heading-4, 18px);
    font-weight: lighter;
  }


  // .bv-details-bar,
  // .bv-action-bar {
  //   display: none !important;
  // }

  ${compose(color, flexbox, grid, layout, position, space)}
`;
export type ProductCardProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & { product: Product };

export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  ...props
}) => {
  const [quickBuyOpen, toggleQuickBuyOpen] = useToggle();

  const offers = product?.offers as Offer;

  const primaryOffer = Array.isArray(offers) ? offers?.[0] : offers;

  const thumbnail =
    product?.image?.filter(image => image.representativeOfPage)?.[0] ??
    product?.image?.[0];

  const image = thumbnail ? (
    <ImgOptimized
      alt={product?.name}
      itemProp="image"
      src={thumbnail?.contentUrl}
    />
  ) : undefined;

  return (
    <>
      <ProductCardStyled
        itemScope
        // itemType="https://schema.org/Product"
        data-id={product?.["@id"]}
        {...props}
      >
        {(image && product?.url && (
          <Link
            onClick={() => {
              gtmEventEcommerceClick(product, product?.brand ?? "");
              setTimeout(() => {
                window.location.href = product?.url;
              }, 600);
            }}
            className="image-container image-clickable"
            // to={product?.url}
            title={product?.title}
          >
            {image}
          </Link>
        )) ||
          image}
        {primaryOffer?.availability && (
          <ItemAvailabilityLabel
            availability={primaryOffer?.availability}
            className="availability"
          />
        )}

       {product?.productID && <div className="bazaarvoice-content">
          <div data-bv-show="inline_rating"
              data-bv-product-id={`${product?.sku}`}
              data-bv-redirect-url={`${product?.url}`}
              data-bv-seo="false"
              ></div>
        </div>}

        <div className="product-information">
          <div className="product-category-wrapper">
            {Array.isArray(product?.category) &&
              product?.category
                .map(category => (
                  <Link
                    className="product-category"
                    id={category?.identifier}
                    key={category?.identifier}
                    itemProp="category"
                    title={category?.name}
                    to={category?.url}
                  >
                    <span itemProp="name">{category?.name}</span>
                  </Link>
                ))
                .pop()}
          </div>

          <div className="product-actions">
            <QuickWishlist product={product} />
            <Button
              p={0}
              onClick={() => {
                toggleQuickBuyOpen();
              }}
            >
              <span className="sr-only">Quick Buy</span>
              {quickBuyOpen ? <MinusIcon /> : <PlusIcon />}
            </Button>
          </div>
        </div>
        {product?.name && (
          <span className="product-name" itemProp="name">
            {product?.name}
          </span>
        )}
        {!quickBuyOpen && primaryOffer && (
          <Price display={{ _: "block", md: "block" }} offer={primaryOffer} />
        )}
        {quickBuyOpen && (
          <QuickBuy
            className="quick-buy"
            marginBottom={{ _: 6, md: 0 }}
            product={product}
            showPrice={true}
            showVariants={product.showOptionsOnLister}
          />
        )}
      </ProductCardStyled>
      {/* <Helmet>
       
      </Helmet> */}
    </>
  );
};
