import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import {
  color,
  compose,
  flexbox,
  grid,
  layout,
  position,
  space,
  ColorProps,
  FlexboxProps,
  GridProps,
  LayoutProps,
  PositionProps,
  SpaceProps,
  VariantProps,
} from "styled-system";
import { ArticleCard } from "./ArticleCard";
import { Banner } from "./Banner";
import { BannerGallery } from "./BannerGallery";

import { Button, Link } from "./Button";
import Carousel from "./Carousel";
import { Modal } from "./Modal";
import { mediaQueries } from "../theme";

import { ReactComponent as Arrow } from "../../static/icons/Arrow.svg";
import { ReactComponent as Tick } from "../../static/icons/Tick.svg";
import { useMediaQuery } from "../hooks/useMediaQuery";

import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const moveInLeft = keyframes`
    0% {
      opacity: 0;
      transform: translate3d(-10rem, 0, 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
`;

const moveOutLeft = keyframes`
    0% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    100% {
      opacity: 0;
      transform: translate3d(10rem, 0, 0);
    }
`;

const moveInRight = keyframes`
    0% {
      opacity: 0;
      transform: translate3d(10rem, 0, 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
`;

const moveOut = keyframes`
    0% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    100% {
      opacity: 0;
      transform: translate3d(-10rem, 0, 0);
    }
`;

const fadeIn = keyframes`
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
`;

const moveInRightColorDots = keyframes`
    0% {
      opacity: 0;
      transform: translate3d(10rem, 10em, 0); 
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 10em, 0); 
    }


`;

const moveInRightColorDotsMobile = keyframes`
    0% {
      opacity: 0;
      transform: translate3d(10rem, 7em, 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 7em, 0);
    }


`;

const moveOutColorDots = keyframes`
    0% {
      opacity: 1;
      transform: translate3d(0, 10em, 0); 
    }

    100% {
      opacity: 0;
      transform: translate3d(-10rem, 10em, 0); 
    }
`;

const moveOutColorDotsMobile = keyframes`
    0% {
      opacity: 1;
      transform: translate3d(0, 7em, 0);
    }

    100% {
      opacity: 0;
      transform: translate3d(-10rem, 7em, 0);
    }
`;

const moveInLeftColorDots = keyframes`
    0% {
      opacity: 0;
      transform: translate3d(-10rem, 10em, 0); 
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 10em, 0); 
    }


`;

const moveInLeftColorDotsMobile = keyframes`
    0% {
      opacity: 0;
      transform: translate3d(-10rem, 7em, 0);
    }

    100% {
      opacity: 1;
      transform: translate3d(0, 7em, 0);
    }


`;

const moveOutLeftColorDots = keyframes`
    0% {
      opacity: 1;
      transform: translate3d(0, 10em, 0); 
    }

    100% {
      opacity: 0;
      transform: translate3d(10rem, 10em, 0); 
    }
`;

const moveOutLeftColorDotsMobile = keyframes`
    0% {
      opacity: 1;
      transform: translate3d(0, 7em, 0);
    }

    100% {
      opacity: 0;
      transform: translate3d(10rem, 7em, 0);
    }
`;

const pulse = keyframes`
  0% {
      background-color: #E2E8F0;
  }

  50% {
      background-color: #CBD5E1;
  }

  100% {
      background-color: #E2E8F0;
  }
`;

const pulseLogo = keyframes`
  0% {
      opacity: .2;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
`;

const WrapperStyled = styled.div`

/* -------------------------------------------------- */
/* ---------------- Skeleton part ------------------ */
/* -------------------------------------------------- */

.skeleton {
  position: absolute;
  top: 0;  
  width: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-radius: 12.72px;
  height: 273px;
  max-width: 217px;
  margin: auto;
  transition: all 0.3s ease-out;
  background-color: #fff;
 

  
  ${mediaQueries.smDown}{
    height: 344px;
    max-width: 100%;
  }

}

.skeleton-text{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0 15px;
}

.skeleton-logo {
    background-image: url("/icons/Logotype.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 100%;
    height: 100%;
    animation: ${pulseLogo} 2s infinite ease-in-out;
}

.skeleton-title{
  width: 100%;
  height: 50px;
  animation: ${pulse} 2s infinite ease-in-out;
}


.skeleton-description span:nth-child(1){
  display: block;
  width: 100%;
  height: 10px;
  animation: ${pulse} 2s infinite ease-in-out;
}

.skeleton-description span:nth-child(2){
  display: block;
  width: 150px;
  height: 10px;
  animation: ${pulse} 2s infinite ease-in-out;
  margin: 5px auto;
}


/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

  .moveInRightColorDots {
    animation: ${moveInRightColorDots} .6s ease-in-out 1s both;

    ${mediaQueries.smDown}{
      animation: ${moveInRightColorDotsMobile} .6s ease-in-out 1s both;
    }
  }

  .moveOutColorDots {
    animation: ${moveOutColorDots} .6s ease-in-out 1s both;

    ${mediaQueries.smDown}{
      animation: ${moveOutColorDotsMobile} .6s ease-in-out 1s both;
    }
  }

  .moveInLeftColorDots {
    animation: ${moveInLeftColorDots} .6s ease-in-out 1s both;

    ${mediaQueries.smDown}{
      animation: ${moveInLeftColorDotsMobile} .6s ease-in-out 1s both;
    }
  }

  .moveOutLeftColorDots {
    animation: ${moveOutLeftColorDots} .6s ease-in-out 1s both;

    ${mediaQueries.smDown}{
      animation: ${moveOutLeftColorDotsMobile} .6s ease-in-out 1s both;
    }
  }

  .fadeIn {
    animation: ${fadeIn} 1.2s ease-in-out .6s both;
  }


  .moveInMainQuestion {
    animation: ${moveInRight} .6s ease-in-out 1s both;
  }

  .moveOutMainQuestion {
    animation: ${moveOut} .6s ease-in-out 1s both;
  } 

  .moveOutLeftMainQuestion {
    animation: ${moveOutLeft} .6s ease-in-out 1s both;
  } 

  .moveInLeftMainQuestion {
    animation: ${moveInLeft} .6s ease-in-out 1s both;
  } 

  .moveOut {
    animation: ${moveOut} .5s ease-in-out .7s both;
  } 

  .moveIn {
    animation: ${moveInRight} .5s ease-in-out .7s both;
  } 

  .moveOutLeft {
    animation: ${moveOutLeft} .5s ease-in-out .7s both;
  } 

  .moveInLeft {
    animation: ${moveInLeft} .5s ease-in-out .7s both;
  } 

  /* -------------------------------------------------- */
  /* ---------------- Animation part end ------------------ */
  /* -------------------------------------------------- */

  h1 {
    text-align: center;
    font-size: var(--font-size-xl);
    max-width: 528px;
    margin: 0 auto;
    opacity: 0;
    transform: translate3d(10rem, 0, 0);

    ${mediaQueries.smDown}{
      padding: 0 20px;
    }

  }

  .sub-title-container {
    width: 530px;
    text-align: center;
    margin: 20px auto 20px;
    opacity: 0;
    transform: translate3d(10rem, 0, 0);

    ${mediaQueries.smDown}{
      width: 100%;
      padding: 0 20px;
    }

  }

  p {
    text-align: center;
    margin-block-end: 2rem;
  }


 
  label.back-btn {
    font-family: "Quicksand Bold", sans-serif;
    position: absolute;
    top: 40px;
    left: 40px;

    ${mediaQueries.smDown}{
      top: 53px;
      left: 20px;
    }


    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  label.page-count {
    text-align: center;
    margin: 22px auto 40px;
    font-family: "Quicksand", sans-serif;
    font-size: var(--font-size-md);


    ${mediaQueries.smDown}{
     margin: 0 auto 40px;
    }
  }



  .slider-container {
    max-width: 678px;
    margin: 0 auto;
    opacity: 0;
    transform: translate3d(10rem, 0, 0);

    .left-nav,
    .right-nav {
      top: 45%;

      &:hover {
        cursor: pointer;
      }
  
      ${mediaQueries.smDown}{
        display: none;
      }
    }
  
    .left-nav {
      transform: rotate(180deg);
      left: -48px;
    }
  
    .right-nav {
      right: -48px;
    }
  }

  .slick-list {

    height: 344px;

    ${mediaQueries.smDown}{
      height: 60vh;
    }

    .slick-track {
      top: 20px;
    }

    button {

      padding: auto !important;
      transition: transform .2s ease-out;
      border-bottom-color: transparent;
      border-bottom-style: solid;
      border-radius: unset;
      padding: unset;


      &.isSelected{
        opacity: 0.4;
        transform: scale(1.1);
      }

  

      .image-container-inner {

        display: flex !important;
        justify-content: center;
        align-items: center;
        border-radius: 12.72px;
        height: 273px;
        max-width: 206px;
        margin: auto;
        transition: all 0.3s ease-out;
        animation: ${fadeIn} 1s ease-in-out .3s both;
       
  
        
      ${mediaQueries.smDown}{
        height: 344px;
        max-width: 258px;
      }
       
  
        img {
          
          object-fit: cover;
          height: 100%;
          width: 100%;
         
          
  
          &.icon {
            height: 40px;
            width: 100%;
            object-fit: none;
            margin-bottom: 25px;
          }
          
        }
  
        .selected-image {
          position: absolute;
          height: 50px;
          width: 50px;
          background-color: #FFFFFF;
          border-radius: 50%;
         
          display: flex;
          align-items: center;
          justify-content: center;
          pointer-events: none;
  
          .selected-tick {
            margin-left: 0;
          }
  
        }
      }
    
    }
   
  }

  .carousel-container {
    overflow: hidden;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    ${mediaQueries.smDown}{
      margin-top: 35px;
    }

    


    .left-nav,
    .right-nav {
      // top: 31em;
      position: relative;

      ${mediaQueries.smDown}{
        display: none;
      }
    }

    .left-nav {
      transform: rotate(180deg);
      left: 0;
    }

    .right-nav {
      right: 0;
    }

    .carousel {
      // display: grid;
      // grid-auto-flow: column;
      // grid-template-columns:  fit-content(0);
      // gap: 20px;
      // cursor: pointer;
      // text-align: center;
      // transition: transform .45s ease-out;
      // white-space: nowrap;

     

      .items {
        height: 60vh;
      }

   
    }


  }
 

  .radio-main-container {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-top: 40px;
    opacity: 0;
    transform: translate3d(10rem, 0, 0);

    ${mediaQueries.smDown}{
      grid-template-rows: max-content;
      grid-template-columns: auto;
      gap: 20px;
      margin-top: 35px;
      padding: 0 20px;
    }

    .radio-container {
      background-color: white;
      border-radius: 12px;
      height: 44px;
      width: 100%;
      padding: 13px 10.42px;
      display: flex;
      align-items: center;

      &:nth-child(3):last-child,
      &:nth-child(7):last-child {
        width: 80%;
        margin: auto;
        grid-column: 1 / span 2;

        ${mediaQueries.smDown}{
          width: 100%;
          grid-column: auto;
        }
      }

      .radio-border {
        border-radius: 3px;
        border: 1px solid #363139;
        margin-right: 14.27px;
        width: 18px;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;

        ${mediaQueries.smDown}{
          margin-right: 26px;
        }
    

        input[type="radio"] {
          padding: 0.5em;
          border-radius: 2px;
          -webkit-appearance: none;
          border: none;
          margin: 0;
          font-size: 12px;

          &:hover {
            cursor: pointer;
          }

          &:checked {
            background-color: #363139;
          }
        }
      }

      label {
        text-transform: uppercase;
        color: #363139;
        font-size: 14px;
        font-family: "Quicksand", sans-serif;
        line-height: 20px;

        display: contents;

        &.isSelected {
          font-family: "Quicksand SemiBold", sans-serif;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .submit-container {

    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      margin: auto;
      width: 241px;5
    }
  }

  .color-dots-container {
    position: relative;
    width: 100%;
    height: 2vh;
    transform: translate3d(40rem, 10em, 0); 
    justify-content: center;
    display: flex;
    margin-top: 40px;
    opacity: 0;

    ${mediaQueries.smDown}{
      transform: translate3d(40rem, 7em, 0); 
      margin-top: 35px;
    }


    .depends-btn {
      margin: 40px auto;
      background-color: #363139;
      width: 282px;
      position: absolute;
      bottom: -20rem;

      ${mediaQueries.smDown}{
        bottom: -23em;
      }
  
    }

    .circle {
      
      padding: 10px;
      border-radius: 50%;
      width: 64.89px;
      height: 64.89px;
      display: flex;
      position: absolute;
      top: 50%;
      left: 46%;
      transition: transform 0.3s ease-out;

      ${mediaQueries.sm}{
        left: 46% !important;

      }

      ${mediaQueries.xsmDown}{
        left: 41% !important;

      }

      ${mediaQueries.smDown}{
        left: 36%;
        width: 60px;
        height: 60px;
      }
  

      &.isSelected {
        font-size: var(--font-size-md);
        font-weight: 600;
        
        span {
          transform: scale(.8);
        }

        
        ${mediaQueries.smDown}{
          font-size: var(--font-size-sm);
        }
      }

      &:nth-child(1) {
        transform: rotate(calc(0 * 1deg)) translate(calc(16rem / 2))
          rotate(calc(0 * -1deg));
        color: #ffffff;

        &.isSelected {
          transform: scale3d(1.384, 1.384, 1.384) rotate(calc(0 * 1deg))
            translate(calc(12rem / 2)) rotate(calc(0 * -1deg));
        }

        ${mediaQueries.smDown}{
          transform: rotate(calc(0 * 1deg)) translate(calc(12rem / 2))
          rotate(calc(0 * -1deg));

          &.isSelected {
            transform: scale3d(1.384, 1.384, 1.384) rotate(calc(0 * 1deg))
              translate(calc(10rem / 2)) rotate(calc(0 * -1deg));
          }
        }
    
  
      }

      &:nth-child(2) {
        transform: rotate(calc(45 * 1deg)) translate(calc(16rem / 2))
          rotate(calc(45 * -1deg));
        &.isSelected {
          transform: scale3d(1.384, 1.384, 1.384) rotate(calc(45 * 1deg))
            translate(calc(12rem / 2)) rotate(calc(45 * -1deg));
        }

        ${mediaQueries.smDown}{
          transform: rotate(calc(45 * 1deg)) translate(calc(12rem / 2))
          rotate(calc(45 * -1deg));
          &.isSelected {
            transform: scale3d(1.384, 1.384, 1.384) rotate(calc(45 * 1deg))
              translate(calc(10rem / 2)) rotate(calc(45 * -1deg));
          }
        }
      }

      &:nth-child(3) {
        transform: rotate(calc(90 * 1deg)) translate(calc(16rem / 2))
          rotate(calc(90 * -1deg));
        &.isSelected {
          transform: scale3d(1.384, 1.384, 1.384) rotate(calc(90 * 1deg))
            translate(calc(12rem / 2)) rotate(calc(90 * -1deg));
        }

        ${mediaQueries.smDown}{
          transform: rotate(calc(90 * 1deg)) translate(calc(12rem / 2))
          rotate(calc(90 * -1deg));
        &.isSelected {
          transform: scale3d(1.384, 1.384, 1.384) rotate(calc(90 * 1deg))
            translate(calc(10rem / 2)) rotate(calc(90 * -1deg));
        }
        }
      }

      &:nth-child(4) {
        transform: rotate(calc(135 * 1deg)) translate(calc(16rem / 2))
          rotate(calc(135 * -1deg));
        color: #ffffff;
        &.isSelected {
          transform: scale3d(1.384, 1.384, 1.384) rotate(calc(135 * 1deg))
            translate(calc(12rem / 2)) rotate(calc(135 * -1deg));
        }

        ${mediaQueries.smDown}{
          transform: rotate(calc(135 * 1deg)) translate(calc(12rem / 2))
          rotate(calc(135 * -1deg));
          
          &.isSelected {
            transform: scale3d(1.384, 1.384, 1.384) rotate(calc(135 * 1deg))
              translate(calc(10rem / 2)) rotate(calc(135 * -1deg));
          }
        }
      }

      &:nth-child(5) {
        transform: rotate(calc(180 * 1deg)) translate(calc(16rem / 2))
          rotate(calc(180 * -1deg));
        color: #ffffff;
        &.isSelected {
          transform: scale3d(1.384, 1.384, 1.384) rotate(calc(180 * 1deg))
            translate(calc(12rem / 2)) rotate(calc(180 * -1deg));
        }

        ${mediaQueries.smDown}{
          transform: rotate(calc(180 * 1deg)) translate(calc(12rem / 2))
          rotate(calc(180 * -1deg));
          
          &.isSelected {
            transform: scale3d(1.384, 1.384, 1.384)rotate(calc(180 * 1deg))
              translate(calc(10rem / 2)) rotate(calc(180 * -1deg));
          }
        }
      }

      &:nth-child(6) {
        transform: rotate(calc(225 * 1deg)) translate(calc(16rem / 2))
          rotate(calc(225 * -1deg));
        color: #ffffff;
        &.isSelected {
          transform: scale3d(1.384, 1.384, 1.384) rotate(calc(225 * 1deg))
            translate(calc(12rem / 2)) rotate(calc(225 * -1deg));
        }

        ${mediaQueries.smDown}{
          transform: rotate(calc(225 * 1deg)) translate(calc(12rem / 2))
          rotate(calc(225 * -1deg));
          
          &.isSelected {
            transform: scale3d(1.384, 1.384, 1.384) rotate(calc(225 * 1deg))
              translate(calc(10rem / 2)) rotate(calc(225 * -1deg));
          }
        }
      }

      &:nth-child(7) {
        transform: rotate(calc(270 * 1deg)) translate(calc(16rem / 2))
          rotate(calc(270 * -1deg));
        color: #ffffff;
        &.isSelected {
          transform: scale3d(1.384, 1.384, 1.384) rotate(calc(270 * 1deg))
            translate(calc(12rem / 2)) rotate(calc(270 * -1deg));
        }

        ${mediaQueries.smDown}{
          transform: rotate(calc(270 * 1deg)) translate(calc(12rem / 2))
          rotate(calc(270 * -1deg));
          
          &.isSelected {
            transform: scale3d(1.384, 1.384, 1.384) rotate(calc(270 * 1deg))
              translate(calc(10rem / 2)) rotate(calc(270 * -1deg));
          }
        }
      }

      &:nth-child(8) {
        transform: rotate(calc(315 * 1deg)) translate(calc(16rem / 2))
          rotate(calc(315 * -1deg));
        &.isSelected {
          transform: scale3d(1.384, 1.384, 1.384) rotate(calc(315 * 1deg))
            translate(calc(12rem / 2)) rotate(calc(315 * -1deg));
        }

        ${mediaQueries.smDown}{
          transform: rotate(calc(315 * 1deg)) translate(calc(12rem / 2))
          rotate(calc(315 * -1deg));
          &.isSelected {
            transform: scale3d(1.384, 1.384, 1.384) rotate(calc(315 * 1deg))
              translate(calc(10rem / 2)) rotate(calc(315 * -1deg));
          }
        }
      }
    }
  }

  ${compose(color, flexbox, grid, layout, position, space)}
`;

export type ModalFragranceFinderProps = ColorProps &
  FlexboxProps &
  GridProps &
  LayoutProps &
  PositionProps &
  SpaceProps &
  VariantProps & {
    closeModal: React.DispatchWithoutAction;
    modalIsOpen: boolean;
    questions: any;
  };

export const ModalFragranceFinder = ({
  closeModal,
  modalIsOpen = false,
  questions,
  ...props
}) => {
  const bgArray = [
    "#D3CBD6",
    "#EFEEE9",
    "#BFCEA5",
    "#EFEEE9",
    "#FEEBDD",
    "#EFEEE9",
    "#D3CBD6",
    "#EFEEE9",
    "#EFEEE9",
  ];

  const lastQuestionBgArray = [
    "#D3CBD6",
    "#FEEBDD",
    "#BFCEA5",
    "#DEBF9B",
    "#D3CBD6",
    "#BFCEA5",
    "#DEBF9B",
  ];

  const [selectedQuestion, setQuestion] = useState(0);
  const [selectedChoice, setSelectedChoice] = useState("");
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [isForMe, setIsForMe] = useState(false);
  const isMobile = useMediaQuery(mediaQueries.qmd);

  const [isMoved, setIsMoved] = useState(false);
  const [isImgLoaded, setImgLoaded] = useState(false);

  useEffect(() => {
    setQuestion(0);
  }, []);

  const imageLoaderChecker = (event) => {
    setTimeout(() => {
      setImgLoaded(true);
    }, 3000);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000,
      throttleDelay: 10,
      animatedClassName: "animateMe",
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom",
    });
    AOS.refresh();
  }, []);

  const onChangeValue = (event) => {
    setIsMoved(false);
    let eventVal = "";
    let eventProdIds = "";

    if (event.target.tagName.toLowerCase() === "div") {
      eventVal = event.target.textContent;
      eventProdIds = event.target.title;
    } else {
      eventVal =
        event.target.tagName.toLowerCase() === "img"
          ? event.target.alt
          : event.target.title;
      eventProdIds =
        event.target.tagName.toLowerCase() === "img"
          ? event.target.parentElement.parentElement.value
          : event.target.value;
    }

    setSelectedChoice(eventVal);
    localStorage.setItem("question" + (selectedQuestion + 1), eventProdIds);

    if (selectedQuestion === 0 && eventVal == "for me") {
      localStorage.setItem("isForMyself", "true");
      setIsForMe(true);
    } else if (selectedQuestion === 0 && eventVal == "for someone else") {
      localStorage.setItem("isForMyself", "false");
      setIsForMe(false);
    } else if (!isForMe) {
      setIsForMe(false);
    }


    setTimeout(() => {
      if (selectedQuestion + 1 < questions.length) {
        setQuestion(selectedQuestion + 1);
      } else {
        setQuestion(0);
        setIsForMe(false);
        setSelectedChoice("");
        setIsMoved(false);
        setImgLoaded(false);
        computeResult();
        closeModal();
      }
    }, 2000);



    setTimeout(() => {
      setIsMoved(true);
    }, 1000);

    setTimeout(() => {
      setImgLoaded(false);
      setIsMoved(false);
    }, 2000);

  };


  const previousQuestion = (e) => {
    e.preventDefault();

    setTimeout(() => {
      setQuestion(selectedQuestion - 1);
      const element = document.getElementsByClassName("moveIn");
      if (element.length > 0) {
        for (let i = 0; i < element.length; i++) {
          if (element[i].classList.contains("moveIn")) {
            element[i].classList.add("moveInLeft");
            element[i].classList.remove("moveIn");
          }
        }
      }

      const element2 = document.getElementsByClassName("moveInRightColorDots");
      if (element2.length > 0) {
        for (let i = 0; i < element2.length; i++) {
          if (element2[i].classList.contains("moveInRightColorDots")) {
            element2[i].classList.add("moveInLeftColorDots");
            element2[i].classList.remove("moveInRightColorDots");
          }
        }
      }

      const element3 = document.getElementsByClassName("moveInMainQuestion");
      if (element3.length > 0) {
        for (let i = 0; i < element3.length; i++) {
          if (element3[i].classList.contains("moveInMainQuestion")) {
            element3[i].classList.add("moveInLeftMainQuestion");
            element3[i].classList.remove("moveInMainQuestion");
          }
        }
      }
    }, 100);
  };

  const getRandomColor = () => {
    localStorage.setItem(
      "question" + (selectedQuestion + 1),
      JSON.stringify([1,2,3,4,5,6,7,8,9])
    );
    setIsMoved(true);
    setTimeout(() => {
      setQuestion(selectedQuestion + 1);
    }, 1000);
 
    setTimeout(() => {
      setIsMoved(false);
    }, 1000);
  };


  const computeResult = () => {
    let qCount = 0;
    let tempArray: any[] = [];
    let uniqueArray: any[] = [];

    for (let i = 0; i < questions.length; i++) {
      let value = localStorage.getItem("question" + (i + 1));
      if (value) {
        qCount++;
        JSON.parse(value).map((x) => {
          tempArray.push(x);
        });
      }
    }

    tempArray.sort();
    let current = null;
    let ctr = 0;
    let countObj: any[] = [];


    for (let i = 0; i <= tempArray.length; i++) {
      if (tempArray[i] != current) {
          if (ctr > 0) {
              countObj.push({id: current, count: ctr});
          }
          current = tempArray[i];
          uniqueArray.push(current);
     
          ctr = 1;
      } else {
        ctr++;
      }
  }
    let top3Values = countObj.sort((a,b) => b.count - a.count).slice(0,3);
    top3Values.map((val, ind) => localStorage.setItem("result" + (ind+1), val.id));
  }

  function LPBPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className="ui-button left-nav"
        style={
          questions[selectedQuestion].type === "icons"
            ? {
                top: "33%",
              }
            : {}
        }
        onClick={onClick}
      >
        <Arrow className="image-gallery-svg ui zoom-in" />
      </div>
    );
  }

  function LPBNextArrow(props) {
    const { style, onClick } = props;
    return (
      <div
        className="ui-button right-nav"
        style={
          questions[selectedQuestion].type === "icons"
            ? {
                top: "33%",
              }
            : {}
        }
        onClick={onClick}
      >
        <Arrow className="image-gallery-svg" />
      </div>
    );
  }

  const desktopStyle = {
    border: "none",
    borderRadius: 20,
    padding: "40px",
    minHeight: "715px",
    maxWidth: "948px",
    margin: "auto",
    backgroundColor: bgArray[selectedQuestion],
    height: "min-content",
    overflowX: "hidden",
    transition:
      "background-color 0.7s ease, height 0.7s ease, max-width 0.7s ease, min-height 0.7s ease",
  };

  const mobileStyle = {
    inset: "160px 0px",
    minHeight: "565px",
    maxHeight: "600px",
    border: "none",
    borderRadius: 20,
    padding: "50px 0",
    maxWidth: "948px",
    margin: "auto",
    backgroundColor: bgArray[selectedQuestion],
    height: "min-content",
    overflowX: "hidden",
    transition:
      "background-color 0.7s ease, height 0.7s ease, max-width 0.7s ease, min-height 0.7s ease",
  };

  const sliderSettings = {
    lazyLoad: true,
    className: "center",
    centerMode: true,
    dots: false,
    centerPadding: "0",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipe: true,
    initialSlide: 1,
    swipeToSlide: true,
    arrows: true,
    focusOnSelect: true,
    nextArrow: <LPBNextArrow />,
    prevArrow: <LPBPrevArrow />,
    responsive: [
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: selectedQuestion >= 8 ? "65px": "50px" ,
          arrows: false,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0",
          arrows: false,
        },
      },
    ],
  };

  return (
    <Modal
      closeModal={closeModal}
      contentLabel="Fragrance Finder Modal"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className="modal-questions"
      ModalOverlayStyleCustom={isMobile ? mobileStyle : desktopStyle}
    >
      <div className="modal-main">
        <WrapperStyled {...props}>
          {selectedQuestion > 0 ? (
            <label className="back-btn" onClick={previousQuestion}>
              {" "}
              BACK{" "}
            </label>
          ) : null}

          <React.Fragment>
            <label className="page-count " htmlFor="pageCount">
              {selectedQuestion + 1 + " of " + questions.length}
            </label>
            <h1 className={isMoved ? "moveOut" : "moveIn"}>
              {isForMe || selectedQuestion === 0
                ? questions[selectedQuestion].question
                : questions[selectedQuestion].question2}
            </h1>
          </React.Fragment>

          {questions[selectedQuestion].description !== "" ||
          questions[selectedQuestion].description !== null ? (
            <div
              className={
                "sub-title-container " + (isMoved ? "moveOut" : "moveIn")
              }
            >
              <span className="sub-title">
                {isForMe
                  ? questions[selectedQuestion].description
                  : questions[selectedQuestion].description2}
              </span>
            </div>
          ) : null}

          {questions[selectedQuestion].type === "checkbox" ? (
            <div
              className={
                "radio-main-container " +
                (isMoved ? "moveOutMainQuestion" : "moveInMainQuestion")
              }
            >
              {questions[selectedQuestion].choices?.map((item, index) => {
                return (
                  <div className="radio-container">
                    <label
                      className={
                        selectedChoice === item.value ? "isSelected" : ""
                      }
                    >
                      <div className="radio-border">
                        <input
                          key={index}
                          type="radio"
                          value={JSON.stringify(item.perfumeIds)}
                          title={item.value}
                          name="question"
                          checked={selectedChoice === item.value ? true : false}
                          onChange={onChangeValue}
                          onClick={onChangeValue}
                        />
                      </div>
                      {item.value}
                    </label>
                  </div>
                );
              })}
            </div>
          ) : null}

          {questions[selectedQuestion].type === "color" ? (
            <div
              className={
                "color-dots-container " +
                (isMoved ? "moveOutColorDots" : "moveInRightColorDots")
              }
            >
              {questions[selectedQuestion].choices?.map((color, index) => {
                return (
                  <button
                    className={
                      "circle " +
                      (selectedChoice == color.value ? "isSelected" : "")
                    }
                    key={index}
                    style={{ backgroundColor: color.code }}
                    value={JSON.stringify(color.perfumeIds)}
                    title={color.value}
                    onClick={onChangeValue}
                  >
                    {selectedChoice == color.value ? (
                      <span>{color.value} </span>
                    ) : (
                      ""
                    )}
                  </button>
                );
              })}
              <Button
                className="depends-btn"
                variant="primary"
                onClick={getRandomColor}
              >
                DEPENDS ON THE DAY!
              </Button>
            </div>
          ) : null}

          {questions[selectedQuestion].type === "image" ||
          questions[selectedQuestion].type === "icons" ? (
            <div
              className={
                "slider-container " +
                (isMoved ? "moveOutMainQuestion" : "moveInMainQuestion")
              }
            >
              <Slider {...sliderSettings}>
                {questions[selectedQuestion].choices?.map((item, index) => (
                  <button
                    className={
                      selectedChoice == item.alt ? "isSelected active" : ""
                    }
                    title={item.alt}
                    value={JSON.stringify(item.perfumeIds)}
                    key={index}
                    onClick={onChangeValue}
                  >
                    <div
                      className={"image-container-inner"}
                      style={
                        questions[selectedQuestion].type === "icons"
                          ? isMobile ? {
                            backgroundColor: lastQuestionBgArray[index],
                            flexDirection: "column",
                            height: "220px",
                            maxWidth: "220px",
                            padding: "10px",
                          } : {
                              backgroundColor: lastQuestionBgArray[index],
                              flexDirection: "column",
                              height: "210px",
                              maxWidth: "210px",
                              padding: "10px",
                            }
                          : { backgroundColor: lastQuestionBgArray[0] }
                      }
                      title={JSON.stringify(item.perfumeIds)}
                    >
                      <img
                        className={
                          questions[selectedQuestion].type === "icons"
                            ? "icon"
                            : ""
                        }
                        alt={item.alt}
                        src={item.src}
                        onLoad={imageLoaderChecker}
                      />

                      {questions[selectedQuestion].type === "icons"
                        ? item.alt
                        : ""}

                      {selectedChoice == item.alt ? (
                        <div className="selected-image">
                          <Tick className="selected-tick" />
                        </div>
                      ) : null}
                    </div>

                    {isImgLoaded ? null : (
                      <div className="skeleton">
                        <div className="skeleton-text">
                          <div className="skeleton-logo"></div>
                        </div>
                      </div>
                    )}
                  </button>
                ))}
              </Slider>
            </div>
          ) : null}
        </WrapperStyled>
      </div>
    </Modal>
  );
};
