import { themeGet } from "@styled-system/theme-get"
import AOS from "aos"
import "aos/dist/aos.css"
import clsx from "clsx"
import type { MutableRefObject } from "react"
import React, { useEffect, useRef, useState } from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import styled from "styled-components"
import { ArticleCardGrid } from "../components/ArticleCardGrid"
import { Banner } from "../components/Banner"
import { BlurCardGallery } from "../components/BlurCardGallery"
import { Link } from "../components/Button"
import { Layout } from "../components/Layout"
import { Leaf } from "../components/Leaf"
import { SEO } from "../components/SEO"
import { VideoPlayer } from "../components/VideoPlayer"
import { useIntersection } from "../hooks/useIntersection"
import { useMediaQuery } from "../hooks/useMediaQuery"
import { mediaQueries } from "../theme"

const HeaderStyled = styled.header`
  margin-top: calc(var(--header-min-height, 38px) * -1.2);
  height:100vh;

  aside{
    /*aspect-ratio: 16/24;*/
    aspect-ratio:unset;
    position:fixed;
    width:100%;
    top:0;
    height:100vh;

    ${mediaQueries.md}{
      //aspect-ratio: 3/2;
    }
  }

`

const MainStyled = styled.main`
position:relative;
overflow:visible;
background:#fff;
z-index:1;
padding-bottom:20px;

&::before{
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2rem;
  top: -2rem;
  background: inherit;
}


.flower-image-container {


  height:100%;

  div.imageHolder {
    position: relative;
    z-index: 100;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);


    img {
      width:250px;
      &.animateMe{
           opacity: 1;
           transform: translateZ(0);
      }
      // transform: translateX(-100);

      ${mediaQueries.md}{
        width:300px;
      }
    }

  }


  div.animation-holder {

    position: absolute;
    top: 0;

    &.animateMe {
      div {
        opacity: 0.8;
      }

      .image1 {
        width:50%
        transform: rotate(39deg)translate3d(55%,-30%,0);
      }
      div:nth-child(2) {
        transform: rotate(10deg)translate3d(-10%,50%,0);
      }
      div:nth-child(3) {
        transform: rotate(20deg)translate3d(60%,55%,0);
      }
      div:nth-child(4) {
        transform: rotate(20deg)translate3d(-10%,100%,0);
      }
      div:nth-child(5) {
        transform: rotate(7deg)translate3d(105%,15%,0);
      }
      div:nth-child(6) {
        transform: rotate(20deg)translate3d(160%,-60%,0);
      }
      div:nth-child(7) {
        transform: rotate(18deg)translate3d(110%,40%,0);
      }
      div:nth-child(8) {
        transform: rotate(18deg)translate3d(180%,20%,0);
      }


    }

    > div {
      display: inline-block;
      width: 600px;
      height: 400px;
      background: url("./images/flowerPattern-svg.gif") no-repeat;
      background-size: contain;
      z-index: 1;
      opacity: 1;
      position: absolute;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);

      &:nth-child(1) {

          transform: rotate(39deg)translateX(-20%)translateY(15%);
          transition: all 1200ms ease-in;
        // transition: all 1000ms ease-in;
        // left: 0;
        // top: 0;
        // transform: rotate(39deg) translateX(0);
      }

      &:nth-child(2) {
        transition: all 1200ms ease-in;
        transform: rotate(-30deg)translateX(-60%)translateY(10%);
        // left: -20%;
        // top: 10%;
        // transform: rotate(5deg);
      }

      &:nth-child(3) {
        // transform: rotate(-15deg);
        // left: -10%;
        // top: 60%;
        transform: rotate(-18deg)translate3d(-30%,120%,0);
        transition: all 1300ms ease-in;
      }

      &:nth-child(4) {
        // transform: rotate(0deg);
        transform: rotate(-18deg)translate3d(-70%,90%,0);
        transition: all 1500ms ease-in;
        // left: 10%;
        // top: 70%;
      }

      &:nth-child(5) {
        transform: rotate(-18deg)translate3d(120%,60%,0);
        transition: all 1000ms ease-in;
        // transform: rotate(-139deg);
        // transition: all 1000ms ease-in;
        // top: 0;
        // right: -10%
      }

      &:nth-child(6) {
        transition: all 1200ms ease-in;
        transform: rotate(-10deg)translate3d(170%,80%,0);
        // right: -20%;
        // top: 10%;
        // transform: rotate(5deg);
      }

      &:nth-child(7) {
        // transform: rotate(-15deg);
        // right: -10%;
        // top: 60%;
        transform: rotate(70deg)translate3d(120%,-180%,0);
        transition: all 1500ms ease-in;
      }

      &:nth-child(8) {
        transform: rotate(39deg)translate3d(180%,-120%,0);
        // transform: rotate(15deg) ;
        transition: all 1800ms ease-in;
          // right: 0;
          // top: 40%;
      }
    }
  }

}


  section {
    margin-block-end: ${themeGet("space.11")}px;
    margin-block-start: ${themeGet("space.11")}px;
    text-align: center;

    &.section-bottle-figure {
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &.section-leaf {
      background-image: url("https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/blt2279cf650f5d224f/60b9cf41d52e002cc1165b51/1280x1600.jpg?quality=80");
      background-position: center;
      background-size: cover;
      padding-block-end: 48px;
      padding-block-start: 48px;
    }

    > .title,
    > .text,
    > .link {
      margin-block-end: unset;
      margin-block-start: unset;
      margin-inline-end: auto;
      margin-inline-start: auto;
      max-width: 90ch;
    }

    > p {
      &:not(:last-child) {
      }
    }

    > .link {
      display: inline-block;
      font-weight: bold;
      margin-block-end: ${themeGet("space.9")}px;
      text-transform: uppercase;
    }

    > .title {
      font-size: var(--font-size-heading-1, 24px);
      letter-spacing: .5px;
    }
  }

  .mobile-container {
    padding: 0px;
    border-radius: 0px;
  }



  .bottle-figure {
    [data-aos="main-bottle"] {
      transform: rotate(360deg);
      opacity: 0;
      transition-property: transform, opacity;
      &.aos-animate {
        transform: rotate(0);
        opacity: 1;
      }
    }


    display: flex;
    justify-content: center;
    margin-block-start: ${themeGet("space.13")}px;
    margin-block-end: 0px;
    ${mediaQueries.md}{
      margin-block-end: 160px;
    }
    position: relative;

    .pattern {
      width: 100%;
      ${mediaQueries.md}{
        width: 40%;
        max-width: 50%;
      }
      position: absolute;
      -webkit-filter: drop-shadow( 0px 0px 1px rgba(0,0,0,0.1));
      drop-shadow( 0px 0px 1px rgba(0,0,0,0.1))
    }

    .bottle {
      min-width: 300px;
      ${mediaQueries.md}{
        min-width: 300px;
      }
      z-index: 100;
      display: inline-block;
      -webkit-filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, 0.9));
      filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, 0.9));

    }
    [data-aos="first-image"] {
      position: absolute;
      transform: translateX(0);
      opacity: 0;
      transition-property: transform, opacity;
      &.aos-animate {
      transform: rotate(39deg)translateX(-35%)translateY(25%);
      opacity: 0.8;
      }
    }
    [data-aos="second-image"] {
      position: absolute;
      transform: rotate(-15deg)translateX(-130%)translateY(0%);
      opacity: 0;
      transition: all 1000ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(-100%,30%);
      opacity: 0.8;
      }
    }
    [data-aos="third-image"] {
      position: absolute;
      transform: rotate(-15deg)translateX(-75%)translateY(50%);
      opacity: 0;
      transition: all 1500ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(-25%,80%);
      opacity: 0.8;
      }
    }
    [data-aos="fourth-image"] {
      position: absolute;
      transform: rotate(-15deg)translateX(-130%)translateY(50%);
      opacity: 0;
      transition: all 1500ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(-90%,105%);
      opacity: 0.8;
      }
    }
    [data-aos="fifth-image"] {

      transform: rotate(-45deg)translate(100%,-50%);
      transition: all 1000ms ease-in;
      &.aos-animate {
      transform: rotate(25deg)translate(90%,-70%);
      }
    }
    [data-aos="sixth-image"] {

      transform: rotate(-10deg)translateY(-105%)translateX(75%);
      transition: all 1000ms ease-in;
      &.aos-animate {
      transform: rotate(30deg)translateY(-35%)translateX(30%);
      }
    }
    [data-aos="seventh-image"] {

      transform: rotate(-10deg)translate(125%, 10%);
      transition: all 1500ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(110%,25%);
      }
    }
    [data-aos="eight-image"] {

      transform: rotate(-15deg)translate(35%,30%);
      transition: all 1500ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(45%,50%);
      }
    }

  }

  .whats-behind-wrap{
    position:relative;
    z-index:1;
  }

  .animation-parent{
    position:absolute;
    display:block;
    width:100%;
    clip: rect(0, auto, auto, 0);
    height:100%;
    top:0;
    z-index:-1;
  }

  .ontop{
    position:relative;
    z-index:5;
  }

  .animation-wrap{
    display:block;
    width:100%;
    position:fixed;
    top:0%;
    transform: scale(1.15) translateX(-43.5%);

    min-width:1600px;
    left: 50%;




    .leaf-1 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(35deg) translate3d(-18%, 13%, 0);
      display: block;
      opacity:0;
    }

    .leaf-2 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(-11deg) translate3d(-34%, 18%,0);
      display: block;
      opacity:0;
    }

    .leaf-3 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(-12deg) translate3d(-38%,50%,0);
      display: block;
      opacity:0;
    }

    .leaf-4 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(-1deg) translate3d(-86%,83%,0);
      opacity: 0;
    }

    .leaf-5 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(-21deg) translate3d(-9%,-18%,0);
      opacity: 0;
    }

    .leaf-6 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(-37deg) translate3d(-6%,-2%,0);
      opacity: 0;
    }

    .leaf-7 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(55deg) translate3d(12%,18%,0);
      opacity: 0;
      z-index:1;
    }

    .leaf-8 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(37deg) translate3d(4%,72%,0);
      opacity:0;
    }

  }


  .animation-wrap-start, .aos-animate{
    transform: scale(1.15) translateX(-43.5%);

    .leaf-1 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(35deg) translate3d(1%, 1%, 0);
      display: block;
      opacity:1;
    }

    .leaf-2 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(18deg) translate3d(-20%, 18%,0);
      display: block;
      opacity:1;
    }

    .leaf-3 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(9deg) translate3d(-17%,50%,0);
      display: block;
      opacity:1;
    }

    .leaf-4 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(23deg) translate3d(-40%,85%,0);
      opacity: 0.8;
    }

    .leaf-5 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(7deg) translate3d(-35%,14%,0);
      opacity: 1;
    }

    .leaf-6 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(0deg) translate3d(-8%,1%,0);
      opacity: 1;
    }

    .leaf-7 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(18deg) translate3d(2%,43%,0);
      opacity: 1;
      z-index:1;
    }

    .leaf-8 {
      position: absolute;
      width: 75%;
      transition: all 1.5s ease-in-out;
      transform: rotate(18deg)translate3d(-21%,58%,0);
      opacity: 1;
    }

  }

  .campaign-blurcard{
    .items{
      grid-auto-columns:unset;
      grid-template-columns: unset;

      .react-card-back{
        aside{
          aspect-ratio:unset;
        }
      }

    }

  }

  .campaign-imagebanner{
    aspect-ratio:unset;
    height: 70vh;
  }


`
const ButtonStyled = styled.button`
    letter-spacing: 1px;
    position: fixed;
    background: black;
    color: white;
    right: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 9px 12px;
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    top: 40%;
    z-index: 99;
`

const CampaignPage: React.FC = () => {
  const headerRef = useRef() as MutableRefObject<HTMLElement>
  const mainRef = useRef() as MutableRefObject<HTMLElement>
  const sectionOneRef = useRef() as MutableRefObject<HTMLElement>

  const [isToggle, setToggle] = useState(false)

  useEffect(() => {
    // window.addEventListener("scroll", handleScroll);
    // return () => window.removeEventListener("scroll", handleScroll);
    AOS.init({
      duration: 1000,
      throttleDelay: 10,
      animatedClassName: "animateMe",
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom"
    })
    AOS.refresh()

    addEventsAnimation()


    const sectionViewPortChecker = el => {
      const scroll = window.scrollY || window.pageYOffset
      const boundsTop = el.getBoundingClientRect().top + scroll

      const viewport = {
        top: scroll,
        bottom: scroll + window.innerHeight,
      }

        const bounds = {
        top: boundsTop,
        bottom: boundsTop + el.clientHeight,
      }

      return ( bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom )
        || ( bounds.top <= viewport.bottom && bounds.top >= viewport.top );
    }

    const raf =
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function( callback ) {
          window.setTimeout( callback, 1000 / 60 )
    }

    const introChecker = document.querySelector( '.introTrigger' )

    const handler = () => raf( () => {

      if(sectionViewPortChecker(introChecker)){

        const element = document.getElementById("animation-wrap")
        element?.classList.add("animation-wrap-start")

        if(!element?.classList.contains("scrolled")){
          // console.log('scrolled');
          const startPoint = document.getElementById("starPoint")
          // console.log(startPoint?.offsetTop)
          let startPointOffset = startPoint?.offsetTop - 150
          window.scroll({
            top: startPointOffset,
            behavior: 'smooth',
          })
        }

        element?.classList.add("scrolled")

        //
      }

      if(!sectionViewPortChecker(introChecker)){
        const element = document.getElementById("animation-wrap")
        element?.classList.remove("animation-wrap-start")
        element?.classList.remove("scrolled")
      }

    })

    handler()
    window.addEventListener( 'scroll', handler )


  }, [])


  const addEventsAnimation = () => {

    let isElement = false;
    // @ts-ignore
    document.addEventListener("aos:in:customEventName", (e) => {
      // @ts-ignore
      document.getElementById("MyElement").classList.toggle("animateMe")
      // @ts-ignore
      document.getElementById("MyBottle").classList.toggle("animateMe")


      isElement = !isElement;
      setToggle(isElement)

      // console.log(isElement)


    })

  }


  const isMobile = useMediaQuery(mediaQueries.qmd)

  const bottle = () => {
    return useMediaQuery(mediaQueries.qmd) ? "60%" : "18%"
  }
  const backgroundColor = () => {
    return useMediaQuery(mediaQueries.qmd) ? "#fff" : `${themeGet("colors.lightgrey")}`
  }
  const displayBtn = () => {
    return useMediaQuery(mediaQueries.qmd) ? "block" : "none"
  }

  const headerIntersection = useIntersection(headerRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.2
  })

  const mainIntersection = useIntersection(mainRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.2
  })

  const sectionOneIntersection = useIntersection(sectionOneRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.2
  })

  const tumble = [{ transform: "translateY(-50%)", color: "#000" }]

  const timing = {
    duration: 2000,
    iterations: 1
  }

  return (
    <ParallaxProvider>

      <Layout transparent={headerIntersection ?.isIntersecting}>
        <SEO title="La Perla Eau de Parfum" />
        <a href="/lp-signature-orig-edp-50ml-product/">
          <ButtonStyled>
            SHOP
          </ButtonStyled>
        </a>



        <HeaderStyled ref={headerRef}>
          {isMobile ? 
          <VideoPlayer
            alt="something"
            layout="hero"
            muted={true}
            url="https://eu-assets.contentstack.com/v3/assets/blt2bb45aeece463821/bltbeb762bbc009a4c8/60be0f4beb62c12035d9a15d/laperla_10s_9x16_mobile_comp_1.5k_480.mp4"
          />:
          <VideoPlayer
            alt="something"
            layout="hero"
            muted={true}
            url="/videos/hero3.mp4"
          />}
        </HeaderStyled>


        <MainStyled id="starPoint" style={{ backgroundColor: backgroundColor() }} ref={mainRef}>

          <section className="animation-parent">
            <div className="animation-wrap" id="animation-wrap">
                <img className="leaf-1" src="/images/flowerPattern-svg.gif" alt="leaf" />
                <img className="leaf-2" src="/images/flowerPattern-svg.gif" alt="leaf" />
                <img className="leaf-3" src="/images/flowerPattern-svg.gif" alt="leaf" />
                <img className="leaf-4" src="/images/flowerPattern-svg.gif" alt="leaf" />
                <img className="leaf-5" src="/images/flowerPattern-svg.gif" alt="leaf" />
                <img className="leaf-6" src="/images/flowerPattern-svg.gif" alt="leaf" />
                <img className="leaf-7" src="/images/flowerPattern-svg.gif" alt="leaf" />
                <img className="leaf-8" src="/images/flowerPattern-svg.gif" alt="leaf" />
              </div>
          </section>

          <div className="flower-image-container ontop" id="parent">
            <section className={clsx("section-bottle-figure")} ref={sectionOneRef}>
              <span className={clsx("animated")}>EAU DE PARFUM</span>
              <h2 className={clsx("animated", "heading-1")}>
                La Perla Eau de Parfum
              </h2>
              <p className={clsx("animated")}>
                An elegant floral bouquet, as radiant, layered and confident as the
                woman who wears it.
              </p>
              <Link className={clsx("animated", "link introTrigger")} to="/lp-signature-orig-edp-50ml-product/">
                Discover More
              </Link>


              <div className="imageHolder">


                {isToggle ?
                  <img data-aos="fade-down"
                    //style={{ maxWidth: bottle() }}
                    className={clsx("", "bottle")}
                    src="/images/bottle_300px.png"
                    alt="bottle"
                    id="MyBottle"
                  />
                  :

                  <img data-aos="fade-up"
                    data-aos-offset="300"
                    //style={{ maxWidth: bottle() }}
                    className={clsx("", "bottle")}
                    src="/images/bottle_300px.png"
                    alt="bottle"
                    id="MyBottle"
                  />
                }
              </div>

            </section>

            {/*
            <section className={clsx("container whats-behind-wrap", `${isMobile ? "mobile-container" : "container"}`)}>

            </section>
            */}

          </div>

                {/*
            <h2 data-aos="fade-left" data-aos-id="customEventName"
            data-aos-anchor-placement="top-center" data-aos-offset="-150">.
            </h2>
                */}
            {/*<div className="animation-holder" data-aos="animateMe" data-aos-offset="-50"></div>*/}


         {!isMobile &&
          <section className={clsx("container", `${isMobile ? "mobile-container" : "container"}`)}>

            <div className="whats-behind-wrap">
              <h2 className={clsx("heading-1")}>What's behind the scent?</h2>
              <p>
              {process.env.GATSBY_ENV?.toLowerCase().includes("us") ? "Luxurious, sustainable, and ethically sourced: from Haitian Vetiver and Absolute Jasmine from Givaudan’s Orpur® ingredients collection to the radiant, safe-synthetic accord of Violet Leaves, explore the fine ingredients that make this scent truly beautiful." : "Luxurious, sustainable, and ethically sourced: from Haitian Vetiver and Absolute Jasmine from Givaudan’s Orpur® ingredients collection, to the radiant, safe-synthetic accord of Violet Leaves, explore the fine ingredients that make this scent truly beautiful. "}
              </p>
            </div>

            <BlurCardGallery className={clsx("campaign-blurcard")}
              items={[
                {
                  image: {
                    alt: "What’s behind the scent?",
                    src: "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/bltfd51022bf94eb9b9/60b7508fe9d21b6292e84feb/campaign_card_3.jpg?quality=80&width=600"
                  },
                  link: { src: "/test", title: "Learn more" },
                  title: "What’s behind the scent?",
                  isVideo: true,
                  videoUrl: "https://player.vimeo.com/video/553652018"
                },
                {
                  image: {
                    alt: "What’s behind the scent?",
                    src: "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/blt6c359d3dc5008615/60b7509420d08559629934af/campaign_card_1.jpg?quality=80&width=600&"
                  },
                  link: { src: "/test", title: "Learn more" },
                  title: "What’s behind the scent?",
                  isVideo: true,
                  videoUrl: "https://player.vimeo.com/video/553652138"
                },
                {
                  image: {
                    alt: "What’s behind the scent?",
                    src: "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/bltdc749015af0f7da6/60b7509070aab8612a1dd3b5/campaign_card_2.jpg?quality=80&width=600"
                  },
                  link: { src: "/test", title: "Learn more" },
                  title: "What’s behind the scent?",
                  isVideo: true,
                  videoUrl: "https://player.vimeo.com/video/553652070"
                }
              ]}
            />
          </section>
            }

          <section className={clsx("container", `${isMobile ? "mobile-container" : "container"}`)} >
            <Banner
              backgroundPosition="center 22.5%"
              className={clsx("banner campaign-imagebanner fade")}
              image={{
                alt: "Model close-up",
                src: "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/blta6edaa8bf68e9a6f/60b7501a70aab8612a1dd3ab/campaignBanner.jpeg?quality=80&width=1800&"
              }}
              isMobile={isMobile}
              justifyItems="center"
              layout="overlay"
              text=""
              iosHeight={true}
              title="What’s behind the woman we see?"
              isAnimated={true}

              link={{href:"/blog/what-s-behind-the-woman-we-see-"}}
            />
          </section>


          <section className={clsx("container")}>
            <h2 className={clsx("heading-1")}>The making of&hellip;</h2>
            <p className={clsx("text")}>
              Meet the creative minds behind our signature scent, and discover how they brought its story to life.
            </p>
            <ArticleCardGrid
              items={[
                {
                  backgroundPosition: "top right",
                  image: {
                    alt:
                      "​What’s behind the bottle?",
                    src: "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/bltbc3c1b0afb2f00d6/60b74cde20d08559629934a3/Whats_Behind_the_Bottle.jpg?width=500&quality=80"
                  },
                  video: {
                    atl: "",
                    src: "https://player.vimeo.com/video/553650017",
                    muted: false,
                  },
                  //summary:"Suzanne Dalton shares the inspiration and artisanal craft that created the enduringly beautiful flacon.",
                  //title: "​What’s behind the bottle?",
                  isVideo: true,
                },
                {
                  backgroundPosition: "top right",
                  image: {
                    alt:
                      "​​​What’s behind the film?",
                    src: "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/bltd288b558503f5d01/60b74cdfe9d21b6292e84fd7/What_s_Behind_the_Film.jpg?width=500&quality=80"
                  },
                  video: {
                    atl: "",
                    src: "https://player.vimeo.com/video/553650354",
                    muted: false
                  },
                  //summary:
                  //"Go behind the scenes with Massimiliano Bomba, who captured the heart and soul of our fragrance and our egerie Malika on film.",
                  //title: "​​​What’s behind the film?",
                  isVideo: true,
                },
                {
                  backgroundPosition: "top right",
                  image: {
                    alt:
                      "What’s behind the woman we see?",
                    src: "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/blt49d15a2009dab04c/60b74cdd70aab8612a1dd39b/Whats_Behind_the_Woman_We_See.jpg?width=500&quality=80"
                  },
                  video: {
                    atl: "",
                    src: "https://player.vimeo.com/video/553651779",
                    muted: false,
                  },
                 //summary:
                 //   "Meet model Malika El Maslouhi, and see why she encapsulates the modern muse and La Perla's dynamic femininity.",
                 //title: "What’s behind the woman we see?",
                  isVideo: true,
                },
                {
                  backgroundPosition: "top right",
                  image: {
                    alt:
                      "What’s behind the scent?",
                    src: "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/bltfc1f0f77266f25ab/60b74cdfff798158245361f9/What_s_Behind_the_Scent.jpg?width=500&quality=80"
                  },
                  video: {
                    atl: "",
                    src: "https://player.vimeo.com/video/553645284",
                    muted: false,
                  },
                 //summary:
                 //   "Louise Turner, the perfumer behind our signature scent, explains the textures and sensations of its many layers.",
                 //title: "What’s behind the scent?",
                  isVideo: true,
                }
              ]}
            />
          </section>


          {isMobile ?
            <section className={clsx("container")}>
              <Leaf
                colour="lightgrey"
                image={{
                  alt: "White flowers",
                  src: "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/bltf89288b43306acd6/60b7956b70aab8612a1dd5bb/Clean_Beauty_Article_Card_3-4.jpg?quality=80&width=500&"
                }}
                layout="image-and-text-inside"
                text={process.env.GATSBY_ENV?.toLowerCase().includes("us") ? "Have you ever felt like you had to choose between self-care and caring for the world around you? We believe that none of us should have to make that choice. We're proud to create clean beauty products that artfully bridge the gap between luxury and sustainability, so you don't have to sacrifice a thing." : "Have you ever felt like you had to choose between self-care and caring for the world around you? We believe that none of us should have to make that choice. We're proud to create clean beauty products that artfully bridge the gap between luxury and sustainability, so you don't have to sacrifice a thing."}
                title="Our Commitments & Clean Beauty"
                link={{
                  href:"/clean-beauty",
                  title: (process.env.GATSBY_ENV?.toLowerCase().includes("us") ? "Discover More" : "Read More")
                }}
              /></section> : <section className={clsx("container", "section-leaf")}>
              <Leaf
                colour="lightgrey"
                image={{
                  alt: "White flowers",
                  src: "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/bltf89288b43306acd6/60b7956b70aab8612a1dd5bb/Clean_Beauty_Article_Card_3-4.jpg?quality=80&width=500&"
                }}
                layout="image-and-text-inside"
                text={process.env.GATSBY_ENV?.toLowerCase().includes("us") ? "Have you ever felt like you had to choose between self-care and caring for the world around you? We believe that none of us should have to make that choice. We're proud to create clean beauty products that artfully bridge the gap between luxury and sustainability, so you don't have to sacrifice a thing." : "Have you ever felt like you had to choose between self-care and caring for the world around you? We believe that none of us should have to make that choice. We're proud to create clean beauty products that artfully bridge the gap between luxury and sustainability, so you don't have to sacrifice a thing."}
                title="Our Commitments & Clean Beauty"
                link={{
                  href:"/clean-beauty",
                  title: (process.env.GATSBY_ENV?.toLowerCase().includes("us") ? "Discover More" : "Read More")
                }}
              />
            </section>}


        </MainStyled>
      </Layout>
    </ParallaxProvider>
  )
}
export default CampaignPage
