import { themeGet } from "@styled-system/theme-get";
import AOS from "aos";
import "aos/dist/aos.css";
import clsx from "clsx";
import { MutableRefObject, useContext } from "react";
import React, { useEffect, useRef, useState } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import styled from "styled-components";
import { Banner } from "../components/Banner";
import { Button, Link, LinkButton } from "../components/Button";
import { Layout } from "../components/Layout";
import { SEO } from "../components/SEO";
import { useIntersection } from "../hooks/useIntersection";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { mediaQueries } from "../theme";

import { ModalFragranceFinder } from "../components/ModalFragranceFinder";
import Carousel from "../components/Carousel";
import { ShareOverlay } from "../components/ShareOverlay";

import {
  dataQuestionsUS,
  dataAnswersUS,
} from "../utils/questionnairesAndAnswersUsEn";

import { dataQuestions, dataAnswers } from "../utils/questionnairesAndAnswers";

import { ReactComponent as Arrow } from "../../static/icons/Arrow.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const LoaderStyled = styled.div`
  .overlay {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background: #d3cbd6;
    z-index: 99999;
    opacity: 1;
    pointer-events: none;
  }

  .overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: relative;
  }

  .overlay__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .spinner {
    width: 75px;
    height: 75px;
    display: inline-block;
    border-width: 4px;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #363139;
    animation: spin 1s infinite linear;
    border-radius: 100%;
    border-style: solid;
  }

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  .loading {
    // left: -90%;
    // top: 140%;
    // min-width: 260px;
    // margin-top: 20px;
    font-size: var(--font-size-heading-1, 32px);
    font-family: "Tiempos", Times, serif;
    font-weight: lighter;
    text-transform: capitalize;
    position: relative;
    overflow: hidden;
    transform: translate3d(-50%, -60%);

    &:before {
      color: #aaa;
      content: attr(data-loading-text);
    }

    &:after {
      top: 0;
      left: 0;
      width: 0;
      opacity: 1;
      color: #363139;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      content: attr(data-loading-text);
      animation: loading 1.5s infinite linear;
    }

    @keyframes loading {
      0% {
        width: 0;
      }
      100% {
        width: 100%;
      }
    }
  }
`;

const MainStyled = styled.main`


position:relative;
overflow:visible;
background:#fff;
z-index:1;
padding-bottom:20px;

&::before{
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 2rem;
  top: -2rem;
  background: inherit;
}

.content {
  padding-right: 0;
  padding-bottom: 41px;

  ${mediaQueries.smDown}{
    padding-right: 16px;
    padding-left: 16px;
  }

  .text {
    margin-top: 0;
  }
}

.banner-section {
  padding: 0;
  margin-top: 0;
}

.thank-you-banner {
  height: 252px;
  border-radius: 0 0 20px 20px;
  background-color: #FEEBDD;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 20px;
 

  ${mediaQueries.smDown}{
    text-align: center;
    height: 241px;
    border-radius: 0 0 10px 10px;
    padding: 30px 20px;
  }

  h1 {
    ${mediaQueries.smDown}{
      font-size: 20px;
      letter-spacing: 0.4px;
      line-height: 26px;
    }
  }

  span {
    ${mediaQueries.smDown}{
      font-size: 13px;
      letter-spacing: 1px;
      line-height: 17px;
    }

  }

  .finder-btn {
    margin: 20px auto;
    width: 282px;
    position: relative;

    div {
      position: absolute;
      opacity: 0;
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  a {
    font-weight: bold;
    &:hover {
      cursor: pointer;
    }
  }
}

.thank-you-big-banner {
  max-width: 1266px;
  padding: 0;
  .e-container {
    width: 702px;
    margin: 30px auto 0;

    a {
      width: 282px;
      margin: 20px auto 0;
    }

    ${mediaQueries.smDown}{
      max-width: 355px;
      width: 100%;

      a {
        width: 100%;
      }

      span {
        letter-spacing: 1px;
        line-height: 17px;
      }
    }

    
    @media screen and (max-width: 320px){
      max-width: 300px;
    }
    
  }
}

.fragrance-finder-container {

  max-width: 702px;
  margin: 0 auto;

  ${mediaQueries.smDown}{
    padding: 0 30px;
  }

  button {
    margin: 2px auto 50px;
    width: 282px;

    ${mediaQueries.smDown}{
      width: 100%;
    }
  }


  div.imageHolder {
    position: relative;
    z-index: 100;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);


    img {
      width:250px;
      &.animateMe{
           opacity: 1;
           transform: translateZ(0);
      }
      transform: translateX(-100);

      ${mediaQueries.smDown}{
        width:300px;
      }
    }

  }


  div.animation-holder {

    position: absolute;
    top: 0;

    &.animateMe {
      div {
        opacity: 0.8;
      }

      .image1 {
        width:50%
        transform: rotate(39deg)translate3d(55%,-30%,0);
      }
      div:nth-child(2) {
        transform: rotate(10deg)translate3d(-10%,50%,0);
      }
      div:nth-child(3) {
        transform: rotate(20deg)translate3d(60%,55%,0);
      }
      div:nth-child(4) {
        transform: rotate(20deg)translate3d(-10%,100%,0);
      }
      div:nth-child(5) {
        transform: rotate(7deg)translate3d(105%,15%,0);
      }
      div:nth-child(6) {
        transform: rotate(20deg)translate3d(160%,-60%,0);
      }
      div:nth-child(7) {
        transform: rotate(18deg)translate3d(110%,40%,0);
      }
      div:nth-child(8) {
        transform: rotate(18deg)translate3d(180%,20%,0);
      }


    }

    > div {
      display: inline-block;
      width: 600px;
      height: 400px;
      background: url("./images/flowerPattern.svg") no-repeat;
      background-size: contain;
      z-index: 1;
      opacity: 1;
      position: absolute;
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);

      &:nth-child(1) {

          transform: rotate(39deg)translateX(-20%)translateY(15%);
          transition: all 1200ms ease-in;
        // transition: all 1000ms ease-in;
        // left: 0;
        // top: 0;
        // transform: rotate(39deg) translateX(0);
      }

      &:nth-child(2) {
        transition: all 1200ms ease-in;
        transform: rotate(-30deg)translateX(-60%)translateY(10%);
        // left: -20%;
        // top: 10%;
        // transform: rotate(5deg);
      }

      &:nth-child(3) {
        // transform: rotate(-15deg);
        // left: -10%;
        // top: 60%;
        transform: rotate(-18deg)translate3d(-30%,120%,0);
        transition: all 1300ms ease-in;
      }

      &:nth-child(4) {
        // transform: rotate(0deg);
        transform: rotate(-18deg)translate3d(-70%,90%,0);
        transition: all 1500ms ease-in;
        // left: 10%;
        // top: 70%;
      }

      &:nth-child(5) {
        transform: rotate(-18deg)translate3d(120%,60%,0);
        transition: all 1000ms ease-in;
        // transform: rotate(-139deg);
        // transition: all 1000ms ease-in;
        // top: 0;
        // right: -10%
      }

      &:nth-child(6) {
        transition: all 1200ms ease-in;
        transform: rotate(-10deg)translate3d(170%,80%,0);
        // right: -20%;
        // top: 10%;
        // transform: rotate(5deg);
      }

      &:nth-child(7) {
        // transform: rotate(-15deg);
        // right: -10%;
        // top: 60%;
        transform: rotate(70deg)translate3d(120%,-180%,0);
        transition: all 1500ms ease-in;
      }

      &:nth-child(8) {
        transform: rotate(39deg)translate3d(180%,-120%,0);
        // transform: rotate(15deg) ;
        transition: all 1800ms ease-in;
          // right: 0;
          // top: 40%;
      }
    }
  }

}


  section {
    margin-block-end: ${themeGet("space.11")}px;
    margin-block-start: ${themeGet("space.11")}px;
    text-align: center;

    > .title,
    > .text,
    > .link {
      margin-block-end: unset;
      margin-block-start: unset;
      margin-inline-end: auto;
      margin-inline-start: auto;
      max-width: 90ch;
    }

    > p {
      line-height: 20px;
    }

    > .link {
      display: inline-block;
      font-weight: bold;
      margin-top: 30px;
      margin-block-end: ${themeGet("space.9")}px;
      text-transform: uppercase;
      width: 100%;
      &:hover {
        cursor: pointer;
      }
     
    }

    > .title {
      font-size: var(--font-size-heading-1, 24px);
      letter-spacing: .5px;
    }
  }

  .mobile-container {
    padding: 0px;
    border-radius: 0px;

    ${mediaQueries.smDown}{
      max-width: 375px;
      margin-top: 0;
    }

  }



  .bottle-figure {
    [data-aos="main-bottle"] {
      transform: rotate(360deg);
      opacity: 0;
      transition-property: transform, opacity;
      &.aos-animate {
        transform: rotate(0);
        opacity: 1;
      }
    }


    display: flex;
    justify-content: center;
    margin-block-start: ${themeGet("space.13")}px;
    margin-block-end: 0px;
    ${mediaQueries.md}{
      margin-block-end: 160px;
    }
    position: relative;

    .pattern {
      width: 100%;
      ${mediaQueries.md}{
        width: 40%;
        max-width: 50%;
      }
      position: absolute;
      -webkit-filter: drop-shadow( 0px 0px 1px rgba(0,0,0,0.1));
      drop-shadow( 0px 0px 1px rgba(0,0,0,0.1))
    }

    .bottle {
      min-width: 300px;
      ${mediaQueries.md}{
        min-width: 300px;
      }
      z-index: 100;
      display: inline-block;
      -webkit-filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, 0.9));
      filter: drop-shadow( 0px 0px 5px rgba(0, 0, 0, 0.9));

    }
    [data-aos="first-image"] {
      position: absolute;
      transform: translateX(0);
      opacity: 0;
      transition-property: transform, opacity;
      &.aos-animate {
      transform: rotate(39deg)translateX(-35%)translateY(25%);
      opacity: 0.8;
      }
    }
    [data-aos="second-image"] {
      position: absolute;
      transform: rotate(-15deg)translateX(-130%)translateY(0%);
      opacity: 0;
      transition: all 1000ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(-100%,30%);
      opacity: 0.8;
      }
    }
    [data-aos="third-image"] {
      position: absolute;
      transform: rotate(-15deg)translateX(-75%)translateY(50%);
      opacity: 0;
      transition: all 1500ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(-25%,80%);
      opacity: 0.8;
      }
    }
    [data-aos="fourth-image"] {
      position: absolute;
      transform: rotate(-15deg)translateX(-130%)translateY(50%);
      opacity: 0;
      transition: all 1500ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(-90%,105%);
      opacity: 0.8;
      }
    }
    [data-aos="fifth-image"] {

      transform: rotate(-45deg)translate(100%,-50%);
      transition: all 1000ms ease-in;
      &.aos-animate {
      transform: rotate(25deg)translate(90%,-70%);
      }
    }
    [data-aos="sixth-image"] {

      transform: rotate(-10deg)translateY(-105%)translateX(75%);
      transition: all 1000ms ease-in;
      &.aos-animate {
      transform: rotate(30deg)translateY(-35%)translateX(30%);
      }
    }
    [data-aos="seventh-image"] {

      transform: rotate(-10deg)translate(125%, 10%);
      transition: all 1500ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(110%,25%);
      }
    }
    [data-aos="eight-image"] {

      transform: rotate(-15deg)translate(35%,30%);
      transition: all 1500ms ease-in;
      &.aos-animate {
      transform: rotate(18deg)translate(45%,50%);
      }
    }

  }



  .ontop{
    position:relative;
    z-index:5;
  }

  
 .aos-animate{
    transform: scale(1.15) translateX(-43.5%);
  }


  .campaign-imagebanner{
    aspect-ratio:unset;
    height: 618px;

    ${mediaQueries.smDown}{
       max-height: 250px;
    }

  }

  .thank-you-big-banner {
    max-width: 1266px;
    padding: 0;
  }
 

  .shop-now-products {

    max-width: 1325px;
    margin: 50px auto 0;
  
      .left-nav,
      .right-nav {
        top: 32%;
        .image-gallery-svg {
          width: 30px;
        }
  
        &:hover {
          cursor: pointer;
        }
    
        ${mediaQueries.smDown}{
          display: none;
        }
      }
    
      .left-nav {
        transform: rotate(180deg);
        left: -23px;
      }
    
      .right-nav {
        right: -23px;
        top: 32.8%;
      }
    



    .slick-list {
    
      .product-container {
        
        color: #363139;
        text-align: center;      
        max-width: 382px;
        margin: auto;
        display: flex !important;
        justify-content: center;
        align-items: center;

        

        ${mediaQueries.smDown}{
          max-width: 335px;
        }
    
        .product-inner-container {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          border-radius: 12.72px;
    
            img {
              object-fit: cover;
              object-position: center center;
              width: 100%;
              background-color: rgb(239, 238, 233);
              border-radius: 20px;
              display: block;
              height: 382px;

              ${mediaQueries.smDown}{
                height: 335px;
              }
            }
        
            h2 {
              font-family: "Tiempos Fine";
              font-size: 24px;
              letter-spacing: 0.5px;
              line-height: 30px;
              margin: 20px auto 0;
            }
        
            p {
              font-family: Quicksand;
              font-size: 14px;
              letter-spacing: 1px;
              line-height: 20px;
              margin-bottom: 20px;
            }
    
            a {
              min-width: 200px;
            }
        }  
      }
    }

  }
 

.suggestion-txt {
  max-width: 702px;
  margin: 50px auto;

  ${mediaQueries.smDown}{
    max-width: 355px;
    margin: 30px auto;
  }

  @media screen and (max-width: 320px){
    max-width: 300px;
    margin: 30px auto;
  }
  
}
 


`;

const FragranceFinderPage: React.FC = () => {
  const headerRef = useRef() as MutableRefObject<HTMLElement>;
  const mainRef = useRef() as MutableRefObject<HTMLElement>;
  const sectionOneRef = useRef() as MutableRefObject<HTMLElement>;

  const dataQCopy = process.env.GATSBY_ENV?.toLowerCase().includes("us")
    ? dataQuestionsUS
    : dataQuestions;
  const dataACopy = process.env.GATSBY_ENV?.toLowerCase().includes("us")
    ? dataAnswersUS
    : dataAnswers;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [isCompleteFlag, setCompleteFlag] = useState(false);
  const [questionData, setQuestionData] = useState(dataQCopy);
  const [isForMyself, setForMyself] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState(dataACopy);

  function closeModal() {
    document.body.style.overflow = "scroll";
    setIsOpen(false);
    const resultCount = 3;
    let qCount = 0;
    let tempArray: any[] = [];
    const completionUrl = "quiz=completed";

    for (let i = 0; i < resultCount; i++) {
      let value = localStorage.getItem("result" + (i + 1));
      if (value) {
        qCount++;
        tempArray.push(parseInt(value));
      }
    }

    if (qCount === resultCount) {
      tempArray.sort();
      let newVal = dataAnswers.filter(answers =>
        tempArray.includes(answers.perfumeId)
      );

      setForMyself(
        localStorage.getItem("isForMyself") !== null &&
          localStorage.getItem("isForMyself") == "true"
      );
      setProductData(newVal);
      setIsLoading(true);
      setCompleteFlag(true);

      window.location.hash = completionUrl;
      window.scrollTo(0, 0);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 6000);
  }

  function openModal() {
    localStorage.clear();
    document.body.style.overflow = "hidden";
    setIsOpen(true);
  }

  useEffect(() => {
    window.location.hash = "";
    AOS.init({
      duration: 1000,
      throttleDelay: 10,
      animatedClassName: "animateMe",
      mirror: false, // whether elements should animate out while scrolling past them
      anchorPlacement: "top-bottom",
    });
    AOS.refresh();
  }, []);

  const isMobile = useMediaQuery(mediaQueries.qmd);

  const backgroundColor = () => {
    return useMediaQuery(mediaQueries.qmd)
      ? "#fff"
      : `${themeGet("colors.lightgrey")}`;
  };

  const mainIntersection = useIntersection(headerRef, {
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  });

  function LPBPrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="ui-button left-nav" onClick={onClick}>
        <Arrow className="image-gallery-svg ui zoom-in" />
      </div>
    );
  }

  function LPBNextArrow(props) {
    const { style, onClick } = props;
    return (
      <div className="ui-button right-nav" onClick={onClick}>
        <Arrow className="image-gallery-svg" />
      </div>
    );
  }

  const sliderSettings = {
    lazyLoad: true,
    className: "center",
    centerMode: true,
    dots: false,
    centerPadding: "0",
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    swipe: true,
    initialSlide: 1,
    swipeToSlide: true,
    arrows: false,
    focusOnSelect: true,
    nextArrow: <LPBNextArrow />,
    prevArrow: <LPBPrevArrow />,
    responsive: [
      {
        breakpoint: 959,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "15px",
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0",
        },
      },
    ],
  };

  return (
    <ParallaxProvider>
      <Layout transparent={mainIntersection?.isIntersecting}>
        <SEO title="Fragrance Finder" />

        <MainStyled
          id="starPoint"
          style={{ backgroundColor: backgroundColor() }}
          ref={mainRef}
        >
          {isCompleteFlag ? (
            <React.Fragment>
              <div className="thank-you-banner">
                <h1>
                  {isForMyself
                    ? "Here are some fragrances we feel could be a perfect match for you..."
                    : "Here are some fragrances we feel could be a perfect match for her..."}
                </h1>

                <Button variant="primary" className="finder-btn">
                  Share results
                  <ShareOverlay />
                </Button>
                <Link
                  className={clsx("animated", "link introTrigger")}
                  onClick={openModal}
                >
                  Restart The Quiz
                </Link>
              </div>

              <div className="shop-now-products">
                <Slider {...sliderSettings}>
                  {productData?.map(answers => {
                    return (
                      <div
                        className="product-container"
                        key={answers.perfumeId}
                      >
                        <div className="product-inner-container">
                          <img src={answers.url} alt={answers.perfumeNm} />
                          <h2>{answers.perfumeNm}</h2>
                          <p>{answers.description}</p>
                          <LinkButton
                            variant="primary"
                            className="finder-btn"
                            to={answers.shopUrl}
                          >
                            SHOP NOW
                          </LinkButton>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>

              <section className="suggestion-txt">
                <span>
                  {process.env.GATSBY_ENV?.toLowerCase().includes("us") &&
                    (isForMyself
                      ? "Of course, we all have many facets to our personalities and moods, and many different occasions to attend. That's why we have other suggestions for fragrances we think you will love, too..."
                      : "Of course, we all have many facets to our personalities and moods, and many different occasions to attend. That's why we have other suggestions for fragrances we think she will love, too...")}

                  {!process.env.GATSBY_ENV?.toLowerCase().includes("us") &&
                    (isForMyself
                      ? "We all have many facets to our personalities and moods, and many different occasions to attend. That's why we have other suggestions for fragrances we think you will also love..."
                      : "We all have many facets to our personalities and moods, and many different occasions to attend. That's why we have other suggestions for fragrances we think she will also love...")}
                </span>

                <Link
                  className={clsx("animated", "link introTrigger")}
                  to="/fragrances"
                >
                  Discover More
                </Link>
              </section>

              <section
                className={clsx(
                  "container thank-you-big-banner",
                  `${isMobile ? "mobile-container" : "container"}`
                )}
              >
                <Banner
                  style={{ height: "866px" }}
                  backgroundPosition="center 22.5%"
                  className={clsx("banner campaign-imagebanner fade")}
                  image={{
                    alt: "Fragrance_Finder_Conclusion_Page",
                    src:
                      "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/blt32745ddfe1ef688c/60ee75a397d708179361a3da/Fragrance_Finder_Conclusion_Page.jpg",
                  }}
                  isMobile={isMobile}
                  justifyItems="center"
                  layout="overlay"
                  iosHeight={true}
                  isAnimated={true}
                  link={{ href: "/blog/what-s-behind-the-woman-we-see-" }}
                />
                <div className="e-container">
                  <span>
                    We love it when it's love at first sight, but things don't
                    have to get serious. Our Discovery Set is ideal for
                    exploring our full collection of scents, and we've made sure
                    you can purchase samples to try before committing to that
                    one special fragrance. 
                  </span>
                  <LinkButton
                    variant="primary"
                    className="finder-btn"
                    to="/fragrances/gift-sets/"
                  >
                    DISCOVERY SETS
                  </LinkButton>
                </div>
              </section>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <section
                className={clsx(
                  "container banner-section",
                  `${isMobile ? "mobile-container" : "container"}`
                )}
              >
                <Banner
                  backgroundPosition="center center"
                  className={clsx("banner campaign-imagebanner fade")}
                  image={{
                    alt: "Fragrance_Finder_Intro_Page",
                    src:
                      "https://eu-images.contentstack.com/v3/assets/blt2bb45aeece463821/blt59b4c57d1ac3551e/60ee75a4050bf616505a913b/Fragrance_Finder_Intro_Page.jpg",
                  }}
                  isMobile={isMobile}
                  justifyItems="center"
                  layout="hero"
                  text="Discover a new olfactory experience with our Fragrance Finder."
                  iosHeight={true}
                  title="Fragrance Finder"
                  isAnimated={true}
                />
              </section>
              <div className="fragrance-finder-container ontop" id="parent">
                <section
                  className={clsx("section-bottle-figure")}
                  ref={sectionOneRef}
                >
                  <Button variant="primary" onClick={openModal}>
                    Find My Scent
                  </Button>
                  <p className={clsx("animated")}>
                    Lighthearted and carefree or romantic or sensual. Your
                    favourite scent can trigger you to recall your most special
                    moments and experiences.
                  </p>
                  <p className={clsx("animated")}>
                    Scent is one of the most evocative sensory experiences- it’s
                    a phenomenon we will never tire of exploring. Just like we
                    all have many facets of our own personalities, fragrances
                    can let us explore and express these in the most beautiful
                    and confident way.
                  </p>
                  <p className={clsx("animated")}>
                    Our Fragrance Finder is an uncomplicated and fun way to
                    uncover a new perfume, either for you or a special person in
                    your life. In under a minute, you could be on your way to
                    finding the perfect fragrance partner.
                  </p>
                </section>
              </div>
            </React.Fragment>
          )}
        </MainStyled>
      </Layout>
      <ModalFragranceFinder
        closeModal={closeModal}
        modalIsOpen={modalIsOpen}
        questions={questionData}
      />

      {isLoading ? (
        <LoaderStyled>
          <div className="overlay">
            <div className="overlay__inner">
              <div className="overlay__content">
                <span className="spinner"></span>
                <div className="loading" data-loading-text="La Perla..."></div>
              </div>
            </div>
          </div>
        </LoaderStyled>
      ) : null}
    </ParallaxProvider>
  );
};

export default FragranceFinderPage;
