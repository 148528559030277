import type { ImageObject, ProductGroup } from "schema-dts";

import type {
  BigCommerceGql_Product,
  ProductPageQuery,
} from "../../graphql-types";

import { themeGet } from "@styled-system/theme-get";
import clsx from "clsx";
//import { Formik, Form, FormikHelpers } from "formik"
import { PageProps, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { Accordion } from "../components/Accordion";
import { Breadcrumb } from "../components/Breadcrumb";
import { Button, Link } from "../components/Button";
import { DeliveryAndReturnsInformation } from "../components/DeliveryAndReturnsInformation";
import { IconList } from "../components/IconList";
import { ProductImageGallery } from "../components/ProductImageGallery";
import { Leaf } from "../components/Leaf";
import { Layout } from "../components/Layout";
import { PageSections } from "../components/PageSections";
import { ProductSection } from "../components/ProductSection";
import { SEO } from "../components/SEO";
import { ProductSelectorColour } from "../components/ProductSelectorColour";
import { ProductSelectorSize } from "../components/ProductSelectorSize";
import { ShareOverlay } from "../components/ShareOverlay";
import { QuickWishlist } from "../components/QuickWishlist";

import { QuickBuy } from "../components/QuickBuy";

import { mediaQueries } from "../theme";

//import { getCartId } from "../utils/carts"

import { standardiseBigCommerceProductGroup } from "../utils/standardiseBigCommerceProduct";
import { ImgOptimized, optimizeUrl } from "../components/ImgOptimized";
import { useMediaQuery } from "../hooks/useMediaQuery";
import { gtmEventProductDetailView } from "../utils/gtm";

const EditorialStyled = styled.aside`
  margin-block-start: ${themeGet("space.12")}px;
`;
const BreadcrumbWrapper = styled.div`
  display: none;

  ${mediaQueries.md} {
    display: block;
  }
`;

const MobileHeader = styled.div`
  margin-block-start: 20px;

  ${mediaQueries.md} {
    display: none;
  }

  h1 {
    font-size: 24px;
    line-height: 30px;
  }

  .bazaarvoice-content {
    margin-bottom: 15px;
  }
`;

const ProductStyled = styled.article`
  align-items: start;
  display: grid;

  ${mediaQueries.md} {
    column-gap: 8.33vw;
    grid-template-columns: minmax(min-content, 3fr) 2fr;
  }

  .image-gallery-thumbnails-container {
    grid-template-columns: auto;
  }

  .mobile-iconlist-container {
    margin-top: 30px;
    border-radius: 1px;
    border-top: 2px solid #EFEEE9;
    border-bottom: 2px solid #EFEEE9;
    padding: 30px 0;
  }

  .image-gallery {
    margin-block-end: 24px;
    ${mediaQueries.md} {
        margin-block-end: ${themeGet("space.12")}px;
    }
  }

  main {
    display: grid;
  }

  .bazaarvoice-content {
    margin-bottom: 20px;
  }

  .form {
    margin-block-end: ${themeGet("space.9")}px;
  }

  .description {
    margin-block-end: ${themeGet("space.4")}px;
  }

  .description_extended {
    margin-block-end: ${themeGet("space.9")}px;
  }

  .read-more {
    font-weight: bold;
    justify-content: start;
    margin-block-end: ${themeGet("space.4")}px;
    padding: unset;
  }

  .actions {
    display: grid;
    gap: ${themeGet("space.4")}px;
    grid-auto-flow: column;
    height: 0;
    justify-content: end;
    z-index: 10;
  }

  .details {
    dl {
      display: flow-root;
      list-style: none;
    }

    dt {
      float: left;
      margin-inline-end: 0.5ch;

      &:before {
        content: "•";
        display: inline-block;
        margin-inline-end: 1ch;
      }

      &.separated {
        &:after {
          content: ":";
          display: inline-block;
        }
      }
    }

    dd,
    dt {
      margin-block-end: ${themeGet("space.4")}px;
    }
  }

  .delivery-and-returns-information {
    margin-block-end: ${themeGet("space.9")}px;
    display: none;
  }

  .accordion {
    margin-block-end: ${themeGet("space.6")}px;
    margin-block-start: ${themeGet("space.6")}px;
  }

  .ingedients {
    dt {
      font-weight: bold;
      margin-block-end: ${themeGet("space.5")}px;
    }

    dd {
      margin-block-end: ${themeGet("space.8")}px;
    }

    a {
      display: block;
      margin-bottom: 20px;
    }

    .title-description {
      margin-bottom: 30px;
    }
  }

  .productIconList {
    display: block;
    max-height: unset;

    ${mediaQueries.md} {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
      gap: ${themeGet("space.10")}px;
    }
  }

  .listMobile {
    .item {
      margin: 20px 0;
      ${mediaQueries.md} {
        margin: 0;

        grid-auto-flow: row;
        text-align: center;
      }
    }
  }

  .small-leaf {
    width: 100%;
    display: block;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    --image-height: clamp();
    --image-width: clamp();

    .image-and-text-inside {
      margin: 10px 0;
    }

    .leaf-container {
      padding: 0;

      figure {
        display: none;
      }

      .leaf {
        display: block;
        padding: 20px 44px;
        max-width: 100%;
        width: 100%;
        border-radius: 67px 0;

        ${mediaQueries.md} {
          padding: 36px 40px;
          border-radius: 80px 0;
        }
      }

      .inside {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;
      }

      p:last-child {
        margin-block-end: 0;
      }

      .outside {
        display: none;
      }
    }
  }
  .add-to-bag {

      // ${mediaQueries.smDown} {
      //   background-color: #fff;
      //   position: fixed;
      //   bottom: 0;
      //   left: 0;
      //   width: 100%;

      // }

      button {
        margin-bottom: 32px;

        ${mediaQueries.smDown} {
          // margin-bottom: 10px;
          display: none;

        }
      }

  }
`;

const PDPWrapper = styled.div`

  padding-bottom: 35px;

  ${mediaQueries.sm} {
    padding-bottom: 0;
  }

  button#WAR {
    display: none !important;
  }

  .addToBagMobile-container {
    padding: 20px 90px 17px 20px;
    display: block;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top: solid 1px #dddddd;


    ${mediaQueries.sm} {
      display: none;
    }

    .addToBagMobile {
      width: 100%;
      background-color: #363139;
    }
  }


`;

interface Values {
  identifier: string;
}

type PageContextProduct = PageContextTypeBreadcrumb & {
  node: BigCommerceGql_Product;
};

const ProductPage: React.FC<
  PageProps<ProductPageQuery, PageContextProduct>
> = ({ data, pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext;

  const [readMoreOpen, setReadMoreOpen] = useState(false);
  const isMobile = useMediaQuery(mediaQueries.qmd);

  const productFormatBigCommerce = data?.bigCommerceGQL?.site?.product;

  const product = standardiseBigCommerceProductGroup({
    productFormatBigCommerce,
  }) as ProductGroup | { manufacturedIn?: string };

  const imageGalleryArguments = {
    items: Array.isArray(product?.image)
      ? product?.image?.map((image: ImageObject) => ({
          original: image?.contentUrl,
          thumbnail: optimizeUrl(image?.contentUrl, true),
        }))
      : [
          {
            original: (product?.image as ImageObject)?.contentUrl,
            thumbnail: (product?.image as ImageObject)?.contentUrl,
          },
        ],
  };


  const thumbnail =
    product?.image?.filter(image => image.representativeOfPage)?.[0] ??
    product?.image?.[0];

  const isSample = () => product?.name.toLowerCase().includes("sample");


  useEffect(() => {

    const mainAddtoBag = document.getElementsByClassName("add-to-bag-btn")[0];
    // console.log("mainAddtoBag: ", mainAddtoBag);

    setTimeout(() => {
      document.getElementsByClassName('addToBagMobile')[0].innerHTML = mainAddtoBag?.innerHTML || '';
      // mainAddtoBag?.id = "mainAddtoBagBtn";
      if (isSample()) return;
      document.getElementsByClassName('addToBagMobile')[0].addEventListener('click', () => {
        // console.log("trigerrr");
       mainAddtoBag?.click()});
    }, 100);



  }, []);

  const accordion = [
    {
      title: "Details",
      panel: (
        <section className="details">
          <dl>
            <dt className="separated">SKU</dt>
            <dd>{product?.sku}</dd>
            {product?.manufacturedIn && (
              <React.Fragment>
                <dt>Manufactured in</dt>
                <dd>{product.manufacturedIn}</dd>
              </React.Fragment>
            )}
          </dl>
        </section>
      ),
    },
    {
      title: "Key Ingredients",
      panel: (
        <dl className="ingedients">
          {data?.contentstackProduct?.olfactory_family_group
            ?.olfactory_family_title && (
            <dt>
              {
                data?.contentstackProduct?.olfactory_family_group
                  ?.olfactory_family_title
              }
            </dt>
          )}
          {data?.contentstackProduct?.olfactory_family_group
            ?.olfactory_family && (
            <dd className="title-description">
              {
                data?.contentstackProduct?.olfactory_family_group
                  ?.olfactory_family
              }
            </dd>
          )}

          {data?.contentstackProduct?.ingredients?.map(
            (ingredient, ingredientIndex) => {
              if (ingredient?.type != "All Ingredients")
                return (
                  <React.Fragment key={ingredientIndex}>
                    <dt>{ingredient?.type}</dt>
                    {ingredient?.ingredient?.map(
                      (individualIngredient, individualIngredientIndex) => (
                        <dd key={individualIngredientIndex}>
                          <Link to={individualIngredient?.href}>
                            {individualIngredient?.title}
                          </Link>
                          {individualIngredient?.image?.src && (
                            <ImgOptimized
                              title={individualIngredient?.image?.title}
                              alt={individualIngredient?.image?.alt}
                              src={individualIngredient?.image?.src}
                            />
                          )}
                          <div
                            dangerouslySetInnerHTML={{
                              __html: individualIngredient?.summary as string,
                            }}
                          />
                        </dd>
                      )
                    )}
                  </React.Fragment>
                );
              return "";
            }
          )}

          <Link to={data?.contentstackProduct?.see_all_ingredients_link?.href.includes("?id") ?
          data?.contentstackProduct?.see_all_ingredients_link?.href + "&prodId=" + data?.contentstackProduct?.id :
          data?.contentstackProduct?.see_all_ingredients_link?.href + "?prodId=" + data?.contentstackProduct?.id}>
            {data?.contentstackProduct?.see_all_ingredients_link?.title}
          </Link>
        </dl>
      ),
    },
  ];

  // console.log(data?.contentstackProduct);

  data?.contentstackProduct?.accordion &&
    accordion.push(
      ...data?.contentstackProduct?.accordion[0]?.items.map(panel => ({
        title: panel?.title,
        panel: (
          <div
            dangerouslySetInnerHTML={{
              __html: panel?.panel as string,
            }}
          />
        ),
      }))
    );

  let mobileIconListClass = "";
  data?.contentstackProduct?.icons[0]?.mobileView === "swipe" ? ("productIconList"):(mobileIconListClass = "productIconList listMobile")
  // console.log(mobileIconListClass)
  useEffect(() => {
    gtmEventProductDetailView(product);
    // gtmEventEcommerceClick(product, productFormatBigCommerce.brand.name);
  }, []);

  return (
    <PDPWrapper>
      <Layout>
        <SEO title={product?.name as string} />

        <BreadcrumbWrapper>
          <Breadcrumb crumbs={crumbs} />
        </BreadcrumbWrapper>

        <MobileHeader display={{ _: "block", md: "none" }}>
          <div className="container">
           {product?.productID && <div className="bazaarvoice-content">
              <div data-bv-show="rating_summary" data-bv-product-id={product?.sku}></div>
            </div>}
            <ProductSection product={product} />
            <h1 className={clsx("name", "title")}>{product?.name}</h1>
          </div>
        </MobileHeader>

        <ProductStyled
          className={clsx("container")}
          data-identifier={product?.identifier}
        >
          <div>
            {imageGalleryArguments.items?.length && (
              <ProductImageGallery
                className={clsx("image-gallery")}
                {...imageGalleryArguments}
              />
            )}

          {!isMobile && data?.contentstackProduct?.icons && (
                          <IconList
                            className={clsx(mobileIconListClass)}
                            mobileView={data?.contentstackProduct?.icons[0]?.mobileView}
                            orientation={data?.contentstackProduct?.icons[0]?.orientation}
                            display={{ _: "none", md: "grid" }}
                            gridAutoFlow={{ _: "row", md: "column" }}
                            items={data?.contentstackProduct?.icons[0]?.items}
                          ></IconList>
                        )}
          </div>

          <main>

          {!isMobile && product?.productID && <>
              <div className="bazaarvoice-content">
                <div data-bv-show="rating_summary" data-bv-product-id={product?.sku}></div>
              </div>
              <ProductSection product={product} />
             </>
            }



          <div className="actions">
            {
              !isSample() && <>
                <ShareOverlay />
                <QuickWishlist  product={product}/>
              </>
            }
          </div>


            <QuickBuy
              className="add-to-bag"
              marginBottom={{ _: 6, md: 0 }}
              product={product}
              showPrice={isMobile}
              showVariants={true}
              showToggle={true}
            />





            <div
              className="description"
              dangerouslySetInnerHTML={{
                __html: data?.contentstackProduct?.description as string,
              }}
            />

            <Button
              className="read-more"
              onClick={() => setReadMoreOpen(!readMoreOpen)}
            >
              {readMoreOpen ? "Read less" : "Read more"}
            </Button>
            {readMoreOpen && (
              <div
                className="description-extended"
                dangerouslySetInnerHTML={{
                  __html: data?.contentstackProduct
                    ?.description_extended as string,
                }}
              />
            )}

            <Accordion className="accordion" items={accordion} />

            <DeliveryAndReturnsInformation className="delivery-and-returns-information" />

            {data?.contentstackProduct?.leaf && (
              <Leaf
                className="small-leaf"
                {...data?.contentstackProduct?.leaf[0]}
              />
            )}


            {isMobile && data?.contentstackProduct?.icons && (<div className="mobile-iconlist-container">
                          <IconList
                            className={clsx(mobileIconListClass)}
                            mobileView={data?.contentstackProduct?.icons[0]?.mobileView}
                            orientation={data?.contentstackProduct?.icons[0]?.orientation}
                            display={{ _: "none", md: "grid" }}
                            gridAutoFlow={{ _: "row", md: "column" }}
                            items={data?.contentstackProduct?.icons[0]?.items}
                          ></IconList>
                        </div>)}





            {/* <div className="pl-trustmark" data-size="small" data-brand="beautybylaperla" data-retailer="Brown  Thomas" data-nojquery="true"></div>  */}
          </main>
        </ProductStyled>
        <EditorialStyled>
         {data?.contentstackProduct?.sections && <PageSections sections={data?.contentstackProduct?.sections} />}
        </EditorialStyled>
       { product?.productID && <div className={clsx("container", "bazaarvoice-reviews")}>
          <div data-bv-show="reviews" data-bv-product-id={product?.sku}></div>
        </div>   }
        <Helmet>
          {/* <!-- Removal of old schema implementation as per BV--> */}
          {/* <script type="application/ld+json">{JSON.stringify(product)}</script> */}

          {
            process.env.GATSBY_ENV?.toLowerCase().includes("us") ?
              <script src="//r1.dotdigital-pages.com/resources/sharing/popoverv2.js?sharing=lp-popover&domain=r1.dotdigital-pages.com&id=6ZPY-35T%2Fpup-up%22&delay=5&mobile=&keydismiss=&width=600"></script>
              : process.env.GATSBY_ENV?.toLowerCase().includes("it") ?
                <script src="//emails.laperla.com/resources/sharing/popoverv2.js?sharing=lp-popover&domain=emails.laperla.com&id=6ZPY-56J%2Fitalian-signup%22&delay=0&mobile=&keydismiss=&width=600"></script>
                :
                <script src="//emails.laperla.com/resources/sharing/popoverv2.js?sharing=lp-popover&domain=emails.laperla.com&id=6ZPY-1HE%2Fwebsitepopupbeauty%22&delay=5&mobile=&keydismiss=&width=400"></script>
          }



          {/* <!--Client injected code from BV documentation--> */}
          <script  type="application/ld+json">
            {
              `
                {
                "@context" : "https://schema.org",
                  "@id" : "${product?.url.replace(".com//", ".com/")}",
                  "@type" : "Product",
                  "name" : "${product?.name}",
                  "image" : "${thumbnail?.contentUrl}",
                  "description" : "${product?.description}",
                  "sku" : "${product?.sku}",
                  "brand" : "LAPERLA"
                }
              `
            }
          </script>

        </Helmet>
      </Layout>
      <div className="addToBagMobile-container">
      <Button
            variant="primary"
            py={{ md: 4 }}
            px={{ md: 9 }}
            className="addToBagMobile"
            type="button"
            disabled={isSample()}
          >
            <span>Add to bag</span>
        </Button>
      </div>

    </PDPWrapper>
  );
};


export default ProductPage;

export const query = graphql`
  query ProductPage($id: Int!) {
    contentstackProduct(product_id: { eq: $id }) {
      ...Contentstack_productFragment
    }
    bigCommerceGQL {
      site {
        product(entityId: $id) {
          ...BigCommerceGQL_ProductFragment
        }
      }
    }
  }
`;
